import withWidgetCard from '../../withWidgetCard';
import Presentation from './HiperInsightsConversationChecklistPresentation';
import { formatHeatMapData, infoText, title } from '../utils/utils';
import {
  useGetConversationChecklist,
  useGetConversationChecklistForSingleDay,
} from '../../../hooks/queries/conversationChecklist';
import GenericLoader from '../../GenericLoader';
import { useEffect } from 'react';

const ConversationChecklistForSingleDateWidget = ({
  apiSearchParams,
  ...props
}: Required<BaseQueryProps> & ExpandProps) => {
  const { data, isLoading, isError } =
    useGetConversationChecklistForSingleDay(apiSearchParams);

  const {
    data: expandedData,
    refetch,
    isFetching,
    isFetched,
  } = useGetConversationChecklist(apiSearchParams, false);

  useEffect(() => {
    if (props.isExpanded) {
      refetch();
    }
  }, [props.isExpanded, refetch]);

  if (
    (!isFetched && !data?.length) ||
    (!props.isExpanded && !data?.length) ||
    isError ||
    isLoading ||
    isFetching
  ) {
    return (
      <GenericLoader
        isLoading={isLoading || isFetching}
        isError={isError}
        noData={!data?.length}
      />
    );
  }

  const heatMapData = formatHeatMapData(
    props.isExpanded && expandedData ? expandedData : data,
    props.expansible,
    props.isExpanded
  );

  return (
    <Presentation
      {...(props as ExpandProps)}
      data={heatMapData}
      isSingleData
      singleDateData={data}
    />
  );
};

const ConversationChecklistForSingleDate = withWidgetCard<BaseQueryProps>(
  ConversationChecklistForSingleDateWidget,
  title,
  infoText
);

export { ConversationChecklistForSingleDate };
