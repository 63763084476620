export const rowsPerPageOptions = [10, 20, 50];

export const defaultPagination = {
  first: 0,
  page: 0,
  pageCount: 1,
  rows: rowsPerPageOptions[0],
};

export const FILTER_PREFIX = '&firstName.contains=';
export const FILTER_SUFFIX = '&sort=firstName,asc';
