import { useAtom } from 'jotai';
import { useAlertMessage } from '../context/MessageProvider';
import { navOpened } from '../utils/jotai';
import { cn } from '../utils';
import AlertMessage from './AlertMessage/AlertMessage';

interface ContentLayoutProps {
  children: JSX.Element[];
}

export default function ContentLayout({ children }: ContentLayoutProps) {
  const [isNavOpened] = useAtom(navOpened);
  const { message, severity, onClear } = useAlertMessage();

  return (
    <main
      className={cn(
        'w-full transition-all duration-500 h-screen',
        isNavOpened ? 'pl-64 min-w-[512px]' : 'pl-24 min-w-[320px]'
      )}
    >
      <div className={cn('p-10 pr-[4.375rem] max-w-screen-2xl w-full h-full')}>
        {children}
      </div>
      <div style={{ position: 'absolute', bottom: 5, right: 20 }}>
        <AlertMessage
          detail={message}
          severity={severity}
          handleClear={onClear}
        />
      </div>
    </main>
  );
}
