import { DataTable } from '@xsell/xsell-ui';
import withToggleTab from '../CollapsibleTab/withToggleTab';

const HighlightReel = () => {
  return (
    <DataTable
      value={undefined}
      alwaysShowPaginator={false}
      responsiveLayout="scroll"
      emptyMessage="No information found for Highlight Reel."
    />
  );
};

export default withToggleTab(HighlightReel);
