import {
  useContext,
  createContext,
  Children,
  useState,
  useEffect,
} from 'react';
import { useGetCurrentUser } from '../hooks/queries';
import { pagesPermissions } from '../utils/pagesPermissions';

// eslint-disable-next-line @typescript-eslint/ban-types
type PermissionsList = 'loginSummary' | (string & {});

type Children = { children: React.ReactNode };

interface PermissionsProviderProps {
  getPermission(page: string): boolean;
  getPermissionPagesList: string[];
}

const PermissionsContext = createContext<PermissionsProviderProps | null>(null);

export const usePermissionContext = () => {
  const context = useContext(PermissionsContext);

  if (!context) {
    throw new Error(
      'You must use usePermissionContext within PermissionProvider'
    );
  }

  return context;
};

const PermissionsProviderWrapper = (children: Children) => {
  const [permissionsList, setPermissionsList] = useState<string[]>([]);
  const { data: userData } = useGetCurrentUser();
  const getPermissionPagesList = permissionsList.filter((permissions) =>
    pagesPermissions.includes(permissions)
  );

  useEffect(() => {
    const widgetsList = userData?.attributes.platformAuthorities ?? [];
    if (widgetsList.length) {
      setPermissionsList(widgetsList);
    }
  }, [userData?.attributes.platformAuthorities]);

  const value: PermissionsProviderProps = {
    getPermission: (permission: PermissionsList) =>
      permissionsList.includes(permission),
    getPermissionPagesList,
  };

  return <PermissionsContext.Provider {...children} value={value} />;
};

const PermissionsProvider = ({ children }: Children) => (
  <PermissionsProviderWrapper>{children}</PermissionsProviderWrapper>
);

export default PermissionsProvider;
