import styles from './sidenav.module.css';
import { NavLink } from 'react-router-dom';
import { navData } from './navData';
import { useCallback, useState } from 'react';
import logo from '../../assets/logo.png';
import { useAtom } from 'jotai';
import { navOpened } from '../../utils/jotai';
import SideNavCollapseButton from './SideNavCollapseButton';
import LogoutButton from './LogoutButton';
import { cn } from '../../utils';
import Divider from '../Divider';
import { usePermissionContext } from '../../context/PermissionsProvider';

export default function Sidenav() {
  const [isNavOpened] = useAtom(navOpened);
  const [expandedMenuItem, setExpandedMenuItem] = useState({});
  const [submenuSelected, setSubmenuSelected] = useState(false);

  const submenuStatus = () => {
    setSubmenuSelected(true);
  };

  const updateExpanded = useCallback(
    (clickId) => {
      let isExpanded = true;
      if (`m${clickId}` in expandedMenuItem) {
        isExpanded = expandedMenuItem[`m${clickId}`];
        const clickIdObj = {};
        clickIdObj[`m${clickId}`] = !isExpanded;
        setExpandedMenuItem(clickIdObj);
      } else {
        const newClickIdObj = {};
        newClickIdObj[`m${clickId}`] = isExpanded;
        setExpandedMenuItem(newClickIdObj);
      }
    },
    [expandedMenuItem]
  );

  const handleNavClick = useCallback(
    (e, item) => {
      if (item.children.length) {
        updateExpanded(item.id);
        e.preventDefault();
      } else {
        setSubmenuSelected(false);
      }
    },
    [updateExpanded]
  );

  const { getPermissionPagesList } = usePermissionContext();
  const pagesPermissioned = navData.filter((item) =>
    getPermissionPagesList.includes(item.permissionId)
  );
  return (
    <nav
      className={cn(
        'transition-all duration-500 bg-white shrink-0 fixed h-screen flex flex-col justify-between px-4 z-10',
        isNavOpened ? 'w-64' : 'w-24'
      )}
    >
      <SideNavCollapseButton />
      <div className="block pt-9">
        <div className="flex flex-col">
          <div
            className={cn(
              'w-full flex',
              isNavOpened ? 'pl-7 items-center gap-x-3' : 'justify-center'
            )}
          >
            <img src={logo} alt="supex logo" className="" />
            {isNavOpened && (
              <h2
                className={cn('font-semibold text-lg font-sans animate-fadeIn')}
              >
                Supervisor
              </h2>
            )}
          </div>
          <div className="flex flex-col w-full pt-8 pb-4">
            {pagesPermissioned.map((item) => {
              return (
                <div key={item.id}>
                  {item.id === 10 && <Divider />}
                  <NavLink
                    className={({ isActive }) =>
                      isActive ||
                      (item.children.length && submenuSelected && !isNavOpened)
                        ? styles.activeMenuItem
                        : styles.sideitem
                    }
                    to={item.link}
                    onClick={(e) => handleNavClick(e, item)}
                  >
                    {item.icon}
                    {isNavOpened && (
                      <span className={styles.linkText}>{item.text}</span>
                    )}
                  </NavLink>
                  {expandedMenuItem[`m${item.id}`] ? (
                    <div
                      className={
                        isNavOpened ? styles.showSubMenu : styles.hideSubMenu
                      }
                    >
                      {item.children.map((subitem) => {
                        return (
                          <div key={subitem.id} className="pl-5">
                            <NavLink
                              className={({ isActive }) =>
                                isActive
                                  ? styles.activeSubMenuItem
                                  : styles.submenuitem
                              }
                              to={subitem.link}
                              onClick={submenuStatus}
                            >
                              {subitem.icon}
                              {isNavOpened ? (
                                <span className={styles.linkText}>
                                  {subitem.text}
                                </span>
                              ) : (
                                <span></span>
                              )}
                            </NavLink>
                          </div>
                        );
                      })}
                    </div>
                  ) : null}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className={cn('pb-4', !isNavOpened && 'flex justify-center')}>
        <LogoutButton />
      </div>
    </nav>
  );
}
