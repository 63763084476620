import { HeatMap, HeatMapScale } from '@xsell/xsell-ui';
import {
  heatMapColors,
  heatMapProps,
} from '../utils/conversationChecklistConstants';
import InfoMessage from '../utils/InfoMessage';

interface EngagementConversationChecklistPresentationProps {
  data: ConversationChecklist[];
}

const EngagementConversationChecklistPresentation = ({
  data,
}: EngagementConversationChecklistPresentationProps) => {
  return (
    <>
      <HeatMap
        {...heatMapProps}
        className="w-full h-72"
        data={data}
        margin={{ top: 40, right: 20, bottom: 20, left: 170 }}
      />
      <HeatMapScale className="mb-6 mr-4" colors={heatMapColors} />
      <InfoMessage text="Checklist usage for <strong>Motivation</strong> falls below the recommended usage. Click to view details." />
      ;
    </>
  );
};

export default EngagementConversationChecklistPresentation;
