import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDownLeftAndUpRightToCenter,
  faUpRightAndDownLeftFromCenter,
} from '@fortawesome/free-solid-svg-icons';

export function ExpandIcon() {
  const openExpandedView = () => {
    /* Empty */
  };

  return (
    <span onClick={openExpandedView}>
      <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
    </span>
  );
}

export function CollapseIcon() {
  const closeExpandedView = () => {
    /* Empty */
  };

  return (
    <span onClick={closeExpandedView}>
      <FontAwesomeIcon icon={faDownLeftAndUpRightToCenter} />
    </span>
  );
}
