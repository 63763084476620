import { memo } from 'react';
import withWidgetCard from '../../withWidgetCard';
import CircularTreemapBase from './CircularTreemapBase';

const title = 'Topic Breakdown';
const infoText =
  'The widget displays a breakdown view of the topics handled by agents while using HiPer. Larger circles denote the categories each topic falls under and the smaller circles inside represent the individual topics. The circle size signifies topic frequency, and adjacent labels clarify the categories. Hovering or clicking on circles reveals more information.';

const CircularTreeMapWidget = ({ apiSearchParams }: BaseQueryProps) => (
  <CircularTreemapBase apiSearchParams={apiSearchParams} />
);

export default memo(
  withWidgetCard<BaseQueryProps>(CircularTreeMapWidget, title, infoText)
);
