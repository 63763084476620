import Divider from '../Divider';

export interface TopicData {
  topicName: string;
  percentage?: number | null;
  conversationAmount?: number | null;
}

const TopicBreakDownDetails = ({
  topicName,
  percentage,
  conversationAmount,
}: TopicData) => {
  return (
    <section className="flex w-full h-full max-w-[250px] flex-col flex-wrap font-sans text-left text-md whitespace-nowrap">
      <div>
        <span className="font-semibold after:content-['_']">{topicName}</span>
        <span className="p-1 bg-gray-100 rounded text-colorGray">
          {percentage}%
        </span>
      </div>
      <Divider />
      <div className="text-[#8060ff] font-bold font-sans text-sm before:text-colorGray before:font-normal after:text-colorGray after:font-normal whitespace-break-spaces before:content-['Appears_in_'] after:content-['_Conversation']">
        {conversationAmount}
      </div>
    </section>
  );
};

export default TopicBreakDownDetails;
