import { atom } from 'jotai';
export const navOpened = atom(true);

export enum MyTeamViewModes {
  list = 'list',
  grid = 'grid',
}

export const myTeamViewMode = atom<MyTeamViewModes | null>(
  MyTeamViewModes.grid
);
