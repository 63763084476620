import { HeatMap, HeatMapScale } from '@xsell/xsell-ui';
import { useState } from 'react';
import {
  heatMapProps,
  heatMapColors,
} from '../utils/conversationChecklistConstants';
import HeatMapTooltip from '../utils/HeatMapTooltip';
import InfoMessage from '../utils/InfoMessage';
import Leaderboard from './Leaderboard';

import { ComputedCell, HeatMapDatum } from '@nivo/heatmap';

interface Presentation extends ExpandProps {
  data: ConversationChecklist[];
  isSingleData: boolean;
  singleDateData?: ConversationChecklist[] | null;
}

const HiperInsightsConversationChecklistPresentation = ({
  data,
}: Presentation) => {
  const [modalData, setModalData] = useState<ComputedCell<HeatMapDatum> | null>(
    null
  );

  return (
    <>
      <HeatMap
        {...heatMapProps}
        tooltip={HeatMapTooltip}
        onClick={(cell) => setModalData(cell)}
        className="w-full h-72"
        data={data}
        margin={{ top: 40, right: 20, bottom: 20, left: 170 }}
      />
      <HeatMapScale className="mb-6 mr-4" colors={heatMapColors} />
      <InfoMessage text="Checklist usage for <strong>Motivation</strong> falls below the recommended usage. Click to view details." />
      {modalData && (
        <Leaderboard handleClose={() => setModalData(null)} data={modalData} />
      )}
    </>
  );
};

export default HiperInsightsConversationChecklistPresentation;
