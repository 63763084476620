import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBorderAll,
  faWandMagicSparkles,
  faUsers,
  faGear,
  faCirclePlay,
} from '@fortawesome/free-solid-svg-icons';
import { PagesPermissions } from '../../utils/pagesPermissions';

const getIcon = (icon: IconDefinition) => (
  <FontAwesomeIcon icon={icon} size="lg" />
);

export const navData = [
  {
    id: 0,
    icon: getIcon(faWandMagicSparkles),
    text: 'HiPer Insights',
    link: '/',
    permissionId: PagesPermissions.ViewInsights,
    children: [],
  },
  {
    id: 1,
    icon: getIcon(faUsers),
    text: 'My Team',
    link: '/my-team',
    permissionId: PagesPermissions.ViewTeam,
    children: [],
  },
  {
    id: 2,
    icon: getIcon(faBorderAll),
    text: 'HiPer Coach',
    link: '/hiper-coach',
    permissionId: PagesPermissions.ViewCoaching,
    children: [],
  },
  {
    id: 3,
    icon: getIcon(faCirclePlay),
    text: 'Hiper Play',
    link: '/hiper-play',
    permissionId: PagesPermissions.ViewPlay,
    children: [],
  },
  {
    id: 10,
    icon: getIcon(faGear),
    text: 'Settings',
    link: '/settings',
    permissionId: PagesPermissions.ViewSettings,
    children: [],
  },
];
