import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useGetCurrentUser } from './queries';

export function useAuth() {
  const location = useLocation();
  const { refetch } = useGetCurrentUser();

  useEffect(() => {
    refetch();
  }, [location.pathname, refetch]);
}
