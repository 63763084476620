import {
  DataTable,
  Dropdown,
  DataTableStateEvent,
  FilterMatchMode,
} from '@xsell/xsell-ui';
import { useAgentContext } from '../../context/AgentProvider';
import Star from '../Star/Star';
import { Link } from 'react-router-dom';
import AgentTableColumn from './AgentsTableColumns';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';
import { queryBuilder, splitAgentList } from '../../utils/agent';
import { defaultPagination, rowsPerPageOptions } from './constants';

interface FiltersStateEvent extends DataTableStateEvent {
  filters: {
    firstName: FilterMode<string>;
    agentExternalId: FilterMode<string>;
    'program.id': FilterMode<number>;
    'directSupervisor.id': FilterMode<number>;
  };
}

const defaultLazyFilters: Record<TableFilterOptions, FilterMode> = {
  firstName: { value: null, matchMode: FilterMatchMode.CONTAINS },
  agentExternalId: { value: null, matchMode: FilterMatchMode.CONTAINS },
  'program.id': { value: null, matchMode: FilterMatchMode.EQUALS },
  'directSupervisor.id': {
    value: null,
    matchMode: FilterMatchMode.EQUALS,
  },
};

const initialLazyState: DataTableStateEvent = {
  ...defaultPagination,
  sortField: 'firstName',
  sortOrder: -1,
  multiSortMeta: null,
  filters: defaultLazyFilters,
};

const AgentsDataTable = () => {
  const currentFilterRef = useRef('&sort=firstName,asc');
  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState<AgentsDataTableFilters>({
    firstName: '',
    agentExternalId: '',
    programId: null,
    supervisorId: null,
    sort: {
      field: 'firstName',
      order: 1,
    },
  });

  const [lazyState, setlazyState] =
    useState<DataTableStateEvent>(initialLazyState);

  const {
    toggleAgentWatchList,
    handleTablePaginatorCallback,
    supervisorList,
    programList,
    agentList,
  } = useAgentContext();

  const { watchList, regularList } = splitAgentList(agentList?.content);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce(
      (
        query: string,
        callback: CallBackFn,
        { first, page = 0 }: DataTableStateEvent
      ) =>
        handleTablePaginatorCallback(
          {
            first,
            page,
          },
          query,
          callback
        ),
      500
    ),
    []
  );

  useEffect(() => {
    currentFilterRef.current = queryBuilder(filters);
    debouncedSearch(currentFilterRef.current, setIsLoading, lazyState);
  }, [debouncedSearch, filters, lazyState]);

  const handlePaginate = useCallback(
    ({ first, page = 0, pageCount = 0, rows }: DataTableStateEvent) => {
      handleTablePaginatorCallback(
        {
          first,
          page,
          pageCount,
          rows,
        },
        currentFilterRef.current,
        setIsLoading
      );
    },
    [handleTablePaginatorCallback]
  );

  const handleAgentWatchList = (
    agentId: string,
    isWatchListed: boolean,
    alertMessage: AlertMessageParams
  ) => {
    toggleAgentWatchList(agentId, isWatchListed, alertMessage);
  };

  const watchListBody = ({ watchList, login }: AgentData) => (
    <div className="flex items-center justify-center">
      <Star
        key={login}
        isInWatchList={watchList}
        setAgentWatchList={(willBeWatchListed, alertMessage) =>
          handleAgentWatchList(login, willBeWatchListed, alertMessage)
        }
      />
    </div>
  );

  const handleOnPage = (event: DataTableStateEvent) => {
    setlazyState(event);
    handlePaginate(event);
    setIsLoading(true);
  };

  const handleOnSort = (event: DataTableStateEvent) => {
    setlazyState(event);
    handlePaginate(event);
    setFilters((prev) => ({
      ...prev,
      sort: {
        field: event.sortField,
        order: event.sortOrder,
      },
    }));
  };

  const programFilter = (options: {
    value: null | string;
    filterApplyCallback: (arg0: string | null) => void;
    filterModel: { value: null; matchMode: FilterMatchMode };
  }) => (
    <Dropdown
      value={options.value}
      options={programList.content}
      onChange={(e) => {
        options.filterApplyCallback(e.value);
      }}
      filter
      optionValue="id"
      optionLabel="name"
      placeholder="Filter by program"
    />
  );

  const directSupervisorsFilter = (options: {
    value: null | string;
    filterApplyCallback: (arg0: string | null) => void;
    filterModel: { value: null; matchMode: FilterMatchMode };
  }) => (
    <Dropdown
      value={options.value}
      options={supervisorList.content}
      onChange={(e) => {
        options.filterApplyCallback(e.value);
      }}
      filter
      optionValue="id"
      optionLabel="fullName"
      placeholder="Filter by manager"
    />
  );

  const handleFilters = ({ filters }: FiltersStateEvent) => {
    setFilters((prev) => ({
      ...prev,
      firstName: filters.firstName.value,
      agentExternalId: filters.agentExternalId.value,
      programId: filters['program.id'].value,
      supervisorId: filters['directSupervisor.id'].value,
    }));
  };

  const handleClearFilters = (props: Partial<AgentsDataTableFilters>) => {
    setFilters((prev) => ({
      ...prev,
      ...props,
    }));
  };

  return (
    <DataTable
      value={[...watchList, ...regularList]}
      first={lazyState.first}
      rows={lazyState.rows}
      dataKey="id"
      sortField={lazyState.sortField}
      sortOrder={lazyState.sortOrder}
      onSort={handleOnSort}
      onPage={handleOnPage}
      filters={lazyState.filters}
      onFilter={handleFilters}
      globalFilterFields={[
        'firstName',
        'agentExternalId',
        'agentProfile.program.name',
        'agentProfile.directSupervisor.firstName',
      ]}
      filterDisplay="row"
      emptyMessage="No agents found."
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      currentPageReportTemplate="{first} to {last} of {totalRecords}"
      rowsPerPageOptions={rowsPerPageOptions}
      totalRecords={agentList?.totalCount}
      loading={isLoading}
      paginator
      lazy
    >
      <AgentTableColumn
        body={(agentData: AgentData) => watchListBody(agentData)}
        field="watchList"
        showFilterMenu={false}
      />
      <AgentTableColumn
        body={({ fullName, login }: AgentData) => (
          <Link to={`/my-team/agent/${login}`}>
            <span className="px-2 py-1 text-sm font-medium text-blue-600 bg-blue-100 rounded-md hover:bg-blue-200">
              {fullName}
            </span>
          </Link>
        )}
        field="firstName"
        filter
        filterPlaceholder="Filter by name"
        sortable
        showClearButton
        showFilterMenu={false}
        header="Name"
      />
      <AgentTableColumn
        header="Agent ID"
        field="agentExternalId"
        filterPlaceholder="Filter by Agent id"
        showFilterMenu={false}
        sortable
        filter
      />
      <AgentTableColumn
        body={({ program }: AgentData) => <span>{program?.name ?? ''}</span>}
        header="Program"
        field="program.id"
        filterElement={programFilter}
        showFilterMenu={false}
        onFilterClear={() => handleClearFilters({ programId: null })}
        sortable
        filter
      />
      <AgentTableColumn
        body={({ directSupervisor }: AgentData) => (
          <span>{directSupervisor?.fullName ?? ''}</span>
        )}
        header="Manager"
        field="directSupervisor.id"
        filterElement={directSupervisorsFilter}
        onFilterClear={() => handleClearFilters({ supervisorId: null })}
        showFilterMenu={false}
        sortable
        filter
      />
    </DataTable>
  );
};

AgentsDataTable.displayName = 'AgentsDataTable';

export default memo(AgentsDataTable);
