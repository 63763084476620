import { usePermissionContext } from '../../../context/PermissionsProvider';
import withToggleTab from '../../CollapsibleTab/withToggleTab';
import LoginSummaryWidget from '../../LoginSummary/LoginSummaryWidget';

const AgentActivity = ({ apiSearchParams }: BaseQueryProps) => {
  const { getPermission } = usePermissionContext();

  const hasLoginSummaryPermission = getPermission('se-view_login_summary');

  return (
    <section className="flex child:min-w-[25rem]">
      {hasLoginSummaryPermission && (
        <LoginSummaryWidget apiSearchParams={apiSearchParams} />
      )}
    </section>
  );
};

export default withToggleTab<BaseQueryProps>(AgentActivity);
