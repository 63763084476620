import withToggleTab from '../../CollapsibleTab/withToggleTab';
import TopTopicsWidget from '../../TopTopicsWidget/TopTopicsWidget';
import { usePermissionContext } from '../../../context/PermissionsProvider';
import CircularTreemap from '../../Widgets/CircularTreemap';
import { getWeek } from 'date-fns';
import {
  ConversationChecklistForDateRange,
  ConversationChecklistForSingleDate,
} from '../../ConversationChecklist/HiperInsights';

interface TopicBreakDownProps {
  topicBreakDownQueryParam: APISearchParams;
}

const MapInsights = ({ apiSearchParams }: Required<BaseQueryProps>) => {
  const { getPermission } = usePermissionContext();

  const hasEngagementBreakdownPermission = getPermission(
    'se-view_engagements_breakdown'
  );
  const hasConversationChecklistPermission = getPermission(
    'se-view_convo_checklist_iv'
  );
  const hasTopTopicsPermission = getPermission('se-view_top_five_topics');

  const startDateWeekNumber = getWeek(new Date(apiSearchParams.startDate));
  const endDateWeekNumber = getWeek(
    new Date(apiSearchParams.endDate ?? apiSearchParams.startDate)
  );

  const isSingleDate = apiSearchParams.startDate === apiSearchParams.endDate;

  return (
    <section className="grid grid-cols-3 gap-4">
      {hasEngagementBreakdownPermission && (
        <div className="col-span-2">
          <CircularTreemap apiSearchParams={apiSearchParams} />
        </div>
      )}
      {hasTopTopicsPermission && (
        <TopTopicsWidget queryParams={apiSearchParams} />
      )}
      {hasConversationChecklistPermission &&
        (isSingleDate ? (
          <ConversationChecklistForSingleDate
            apiSearchParams={apiSearchParams}
            expansible
          />
        ) : (
          <ConversationChecklistForDateRange
            apiSearchParams={apiSearchParams}
            defaultExpanded={endDateWeekNumber - startDateWeekNumber > 1}
          />
        ))}
    </section>
  );
};

export default withToggleTab<BaseQueryProps & TopicBreakDownProps>(MapInsights);
