import { WidgetTitle, WidgetCard } from '@xsell/xsell-ui';
import { ApexOptions } from 'apexcharts';
import Chart from 'react-apexcharts';
import { useGetTotalCalls } from '../hooks/queries';
import { formatNumber } from '../utils';

function TotalCallsTitle() {
  return (
    <WidgetTitle
      className="text-colorBlack"
      title="Total Engagements"
      infoText="This Widget counts the engagements taken (while user is logged into HiPer) and grouped by ever hour of the day, giving insights on the Team’s behaviour."
    />
  );
}

function AgentCallsChart({
  agentCallsData,
}: {
  agentCallsData: TotalCallsResponse;
}) {
  const callCount =
    agentCallsData?.timeSeries?.map((item) => item.totalCalls) ?? [];
  const callHour = agentCallsData?.timeSeries?.map((item) => item.hrs) ?? [];

  const options: ApexOptions = {
    grid: {
      show: false,
      padding: {
        left: 2,
        right: 2,
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: '1px',
        colors: ['#8A74F1'],
      },
    },
    colors: ['#8A74F1'],
    theme: { mode: 'light' },
    chart: {
      toolbar: {
        show: false,
      },
      height: 50,
    },
    series: [
      {
        name: 'Call Count',
        data: callCount,
      },
    ],
    plotOptions: {
      bar: {
        dataLabels: {
          position: 'top',
        },
        borderRadius: 6,
        columnWidth: '60%',
        barHeight: '55%',
      },
    },
    xaxis: {
      axisTicks: {
        show: true,
      },
      axisBorder: {
        show: false,
      },
      categories: callHour,
      labels: {
        formatter: function (value) {
          return [
            value
              .toString()
              .toUpperCase()
              .split(value.toString().toUpperCase().slice(-2))[0],
            value.toString().toUpperCase().slice(-2),
          ];
        },
        show: true,
        rotate: 0,
        rotateAlways: false,
        hideOverlappingLabels: false,
        trim: false,
        style: {
          colors: Array(6).fill('#A0ACBB'),
          fontWeight: 400,
          cssClass: 'text-[10px]',
        },
      },
    },
    yaxis: {
      show: false,
    },
    noData: {
      text: 'Data not available',
      align: 'center',
      verticalAlign: 'middle',
    },
    tooltip: { enabled: true },
  };

  return (
    <Chart
      key="agentBarChart"
      type="bar"
      series={options.series}
      options={options}
    />
  );
}

export default function TotalEngagements({ apiSearchParams }: BaseQueryProps) {
  const { data, isLoading, isError } = useGetTotalCalls(apiSearchParams);

  if (isLoading || isError || !data?.totalCount) {
    return (
      <WidgetCard
        title={<TotalCallsTitle />}
        isLoading={isLoading}
        isError={isError}
        noData={!data?.totalCount}
        flat
      />
    );
  }

  return (
    <WidgetCard title={<TotalCallsTitle />} flat>
      <div className="flex flex-col items-center justify-around">
        <AgentCallsChart agentCallsData={data} />
        <span className="mb-4 -mt-2 text-4xl font-bold md:text-4xl text-colorBlack/80">
          {formatNumber(data.totalCount)}
        </span>
      </div>
    </WidgetCard>
  );
}
