import { useState } from 'react';
import FilterOptions from '../components/FilterOptions/FilterOptions';
import DateRangeInput from '../components/DateRangeInput/DateRangeInput';
import InsightsContainer from '../components/HiperInsights/InsightsContainer';
import { formatDateRange, getToday } from '../utils/date';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import AgentActivity from '../components/HiperInsights/AgentActivity';
import EngagementsInsights from '../components/HiperInsights/EngagementsInsights';
import MapInsights from '../components/HiperInsights/MapInsights';
import PerformanceInsights from '../components/HiperInsights/PerformanceInsights';
import CustomerInsights from '../components/HiperInsights/CustomerInsights';
import { usePermissionContext } from '../context/PermissionsProvider';

const Divider = () => <hr className="" />;

const getQueryParams = (
  { startDate, endDate }: APISearchParams,
  filters: FilterCriteria
): APISearchParams & FilterCriteria => {
  return {
    startDate,
    endDate,
    ...filters,
  };
};

export default function Insights() {
  const [inputValues, setInputValues] = useState<DateRangeInputData>({
    dates: getToday(),
    dateRange: null,
  });
  const [filters, setFilters] = useState<FilterCriteria>({
    center: null,
    department: null,
    directSupervisor: null,
    programs: null,
  });

  const dates = formatDateRange(inputValues.dates);

  const multiDateQueryParams = getQueryParams(
    {
      startDate: dates[0],
      endDate: dates[1],
    },
    filters
  );

  const singleDateQueryParams = getQueryParams(
    { startDate: dates[0], endDate: dates[0] },
    filters
  );

  const { getPermission } = usePermissionContext();
  const hasViewFiltersPermission = getPermission('se-view_filters');

  return (
    <InsightsContainer
      input={
        <DateRangeInput
          inputValues={inputValues}
          setInputValues={setInputValues}
          onClear={() =>
            setInputValues({
              dates: [],
              dateRange: null,
            })
          }
        />
      }
    >
      {hasViewFiltersPermission && (
        <FilterOptions selected={filters} setSelected={setFilters} />
      )}
      <AgentActivity
        apiSearchParams={multiDateQueryParams}
        title="Agent Activity"
        OpenIcon={faChevronDown}
        ClosedIcon={faChevronUp}
        titleClassName="text-xl font-bold text-colorBlack"
        backgroundClassName="bg-transparent"
        expanded
      />
      <Divider />
      <EngagementsInsights
        apiSearchParams={multiDateQueryParams}
        title="Engagement Insights"
        OpenIcon={faChevronDown}
        ClosedIcon={faChevronUp}
        titleClassName="text-xl font-bold text-colorBlack"
        backgroundClassName="bg-transparent"
        expanded
      />
      <Divider />
      <MapInsights
        apiSearchParams={multiDateQueryParams}
        topicBreakDownQueryParam={singleDateQueryParams}
        title="Map Insights"
        OpenIcon={faChevronDown}
        ClosedIcon={faChevronUp}
        titleClassName="text-xl font-bold text-colorBlack"
        backgroundClassName="bg-transparent"
        expanded
      />
      <Divider />
      <PerformanceInsights
        title="Performance Insights"
        titleClassName="text-xl font-bold text-colorBlack"
        backgroundClassName="bg-transparent"
        OpenIcon={faChevronDown}
        disabled
      />
      <Divider />
      <CustomerInsights
        title="Customer Insights"
        titleClassName="text-xl font-bold text-colorBlack"
        backgroundClassName="bg-transparent"
        OpenIcon={faChevronDown}
        disabled
      />
      <Divider />
    </InsightsContainer>
  );
}
