import { useGetConversationChecklistForAgent } from '../../../hooks/queries/conversationChecklist';
import Presentation from './AgentProfileConversationChecklistPresentation';
import withToggleTab from '../../CollapsibleTab/withToggleTab';
import GenericLoader from '../../GenericLoader';
import { formatHeatMapData } from '../utils/utils';

interface AgentProfileConversationChecklist {
  agentExternalId: string;
}

const ConversationChecklistAgentProfile = ({
  agentExternalId,
}: AgentProfileConversationChecklist) => {
  const { data, isError, isLoading } =
    useGetConversationChecklistForAgent(agentExternalId);

  if (isError || isLoading || !data?.length) {
    return (
      <GenericLoader
        isLoading={isLoading}
        isError={isError}
        noData={!data?.length}
      />
    );
  }

  const formattedHeatMapData = formatHeatMapData(data);

  return (
    <Presentation
      agentExternalId={agentExternalId}
      data={formattedHeatMapData}
    />
  );
};

export default withToggleTab<AgentProfileConversationChecklist>(
  ConversationChecklistAgentProfile
);
