import { useQuery } from '@tanstack/react-query';
import { useGetCurrentUser } from '.';
import {
  getConversationChecklistForAgent,
  getConversationChecklistForEngagement,
  getConversationChecklistForSingleDay,
  getConversationChecklist,
  getConversationChecklistLeaderboard,
  getAgentRecommendations,
} from '../../services/conversationChecklist';
import { format } from 'date-fns';

export function useGetConversationChecklistForAgent(agentExternalId: string) {
  const { data: userData } = useGetCurrentUser();
  const tenantId = userData?.attributes.tenantPublicId[0];

  const query = useQuery({
    queryKey: ['conversationChecklistForAgent', agentExternalId],
    queryFn: () => getConversationChecklistForAgent(agentExternalId),
    enabled: !!tenantId,
  });
  return query;
}

export function useGetConversationChecklistForEngagement(engagementId: string) {
  const { data: userData } = useGetCurrentUser();
  const tenantId = userData?.attributes.tenantPublicId[0];

  const query = useQuery<ConversationChecklist[]>({
    queryKey: ['conversationChecklistForEngagement', engagementId],
    queryFn: () =>
      getConversationChecklistForEngagement(engagementId, tenantId),
    enabled: !!tenantId,
  });

  return query;
}

export function useGetConversationChecklist(
  queryParams: APISearchParams,
  isEnabled = true
) {
  const { data: userData } = useGetCurrentUser();
  const tenantId = userData?.attributes.tenantPublicId[0];

  const query = useQuery<ConversationChecklist[]>({
    queryKey: [
      'conversationChecklist',
      queryParams.startDate,
      queryParams.endDate,
    ],
    queryFn: () => getConversationChecklist(queryParams),
    enabled: !!tenantId && isEnabled,
  });

  return query;
}

export function useGetConversationChecklistForSingleDay(
  queryParams: APISearchParams,
  isEnabled = true
) {
  const { data: userData } = useGetCurrentUser();
  const tenantId = userData?.attributes.tenantPublicId[0];

  const query = useQuery<ConversationChecklist[]>({
    queryKey: ['conversationChecklist', queryParams.startDate],
    queryFn: () => getConversationChecklistForSingleDay(queryParams),
    enabled: !!tenantId && isEnabled,
  });

  return query;
}

export function useGetConversationChecklistLeaderboard(
  selectedWeek: Date,
  recType: string
) {
  const { data: userData } = useGetCurrentUser();
  const tenantId = userData?.attributes.tenantPublicId[0];
  const formattedDate = format(selectedWeek, 'yyyy-MM-dd');

  const query = useQuery<ConversationChecklistLeaderBoard[]>({
    queryKey: ['conversationChecklist', 'leaderboard', selectedWeek, recType],
    queryFn: () => getConversationChecklistLeaderboard(formattedDate, recType),
    enabled: !!tenantId,
  });

  return query;
}

export function useGetAgentRecommendations(
  weekBeginDate: Date,
  agentExternalId: string,
  recType: string
) {
  const { data: userData } = useGetCurrentUser();
  const tenantId = userData?.attributes.tenantPublicId[0];
  const formattedDate = format(weekBeginDate, 'yyyy-MM-dd');

  const query = useQuery({
    queryKey: [
      'conversationChecklist',
      'agent',
      agentExternalId,
      weekBeginDate,
      recType,
    ],
    queryFn: () =>
      getAgentRecommendations(agentExternalId, formattedDate, recType),
    enabled: !!tenantId,
  });

  return query;
}
