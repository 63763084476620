import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function EngagementDetailsSection({
  detail,
  agent,
  isLoading,
}: {
  detail?: EngagementDetail;
  agent?: AgentData;
  isLoading: boolean;
}) {
  if (isLoading) {
    return (
      <div className="flex flex-col items-center w-full p-5 gap-y-2">
        <FontAwesomeIcon
          icon={faSpinner}
          className="text-colorGray animate-spin"
          size="2x"
        />
        <p>Loading details</p>
      </div>
    );
  }

  return (
    <div>
      <p className="text-sm">
        <span className="min-w-[100px] text-colorBlack inline-block">ID</span>
        <span className="font-light">{detail?.engagement_id ?? '-'}</span>
      </p>
      <p className="text-sm">
        <span className="min-w-[100px] text-colorBlack inline-block">
          Agent Name
        </span>
        <span className="font-light">
          {agent ? `${agent.firstName} ${agent.lastName}` : '-'}
        </span>
      </p>
      <p className="text-sm">
        <span className="min-w-[100px] text-colorBlack inline-block">
          Program
        </span>
        <span className="font-light">{detail?.program_uid ?? 'Default'}</span>
      </p>
      <p className="text-sm">
        <span className="min-w-[100px] text-colorBlack inline-block">
          Topic
        </span>
        <span className="font-light">{detail?.topic_name ?? '-'}</span>
      </p>
      <p className="text-sm">
        <span className="min-w-[100px] text-colorBlack inline-block">
          Duration
        </span>
        <span className="font-light">
          {detail?.call_duration_seconds
            ? new Date(detail.call_duration_seconds * 1000)
                .toISOString()
                .slice(11, 19)
            : '-'}
        </span>
      </p>
    </div>
  );
}
