import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EngagementDetailWidget, ScrollTop } from '@xsell/xsell-ui';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import './ScrollPanelStyle.css';

export default function EngagementTranscriptSection({
  isLoading,
  transcript,
}: {
  isLoading: boolean;
  transcript?: EngagementTranscript[];
}) {
  if (isLoading) {
    return (
      <div className="flex flex-col items-center w-full mt-4 gap-y-2">
        <FontAwesomeIcon
          icon={faSpinner}
          className="text-colorGray animate-spin"
          size="2x"
        />
        <p>Loading transcript</p>
      </div>
    );
  }

  let transcriptWithEvents: EngagementTranscript[] = [];

  if (transcript?.length) {
    transcriptWithEvents = [
      {
        from: 'event',
        text: 'Engagement Start',
        timestamp: transcript?.[0]?.timestamp,
      },
      ...transcript,
      {
        from: 'event',
        text: 'Engagement End',
        timestamp: transcript?.[transcript?.length - 1]?.timestamp,
      },
    ];
  }

  return (
    <div className="flex flex-col h-full gap-2 overflow-auto">
      <span className="pt-4 pl-5 text-lg font-bold text-colorBlack">
        Engagement Transcript
      </span>
      <div className="overflow-auto custombar">
        <EngagementDetailWidget
          data={{
            transcript: transcriptWithEvents,
          }}
        />
        <ScrollTop
          target="parent"
          threshold={100}
          style={{ backgroundColor: '#6366f1' }}
          className="w-[2.4rem] h-[2.6rem] rounded"
          icon="pi pi-arrow-up"
        />
      </div>
    </div>
  );
}
