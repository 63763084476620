import { useGetDashboardFilterOptions } from '../../hooks/queries';
import MultiSelectField from '../ui/MultiSelectField';

const FILTER_CRITERIAS: {
  dataKey: keyof DashboardFilterOptions;
  name: string;
  label: string;
  labelPlural: string;
}[] = [
  {
    dataKey: 'programs',
    name: 'program',
    label: 'Program',
    labelPlural: 'Programs',
  },
  {
    dataKey: 'department',
    name: 'department',
    label: 'Department',
    labelPlural: 'Departments',
  },
  {
    dataKey: 'center',
    name: 'center',
    label: 'Center',
    labelPlural: 'Centers',
  },
  {
    dataKey: 'directSupervisor',
    name: 'manager',
    label: 'Manager',
    labelPlural: 'Managers',
  },
];

type FilterOptionsProps = {
  selected: FilterCriteria;
  setSelected: (filters: FilterCriteria) => void;
};

export default function FilterOptions({
  selected,
  setSelected,
}: FilterOptionsProps) {
  const { data } = useGetDashboardFilterOptions();
  return (
    <div className="grid w-full grid-cols-1 gap-3 sm:grid-cols-2 lg:grid-cols-4">
      {FILTER_CRITERIAS.map((filterCriteria) => {
        const options =
          data?.[filterCriteria.dataKey].map(({ id, name }) => ({
            name,
            value: id,
          })) ?? [];

        return (
          <MultiSelectField
            key={filterCriteria.dataKey}
            name={filterCriteria.name}
            label={filterCriteria.label}
            disabled={!options.length}
            value={selected[filterCriteria.dataKey]}
            onChange={(value) => {
              setSelected({ ...selected, [filterCriteria.dataKey]: value });
            }}
            placeholder={
              options.length
                ? `Select a ${filterCriteria.label}`
                : `No ${filterCriteria.labelPlural} available`
            }
            options={options}
          />
        );
      })}
    </div>
  );
}
