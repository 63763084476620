import { useAtom } from 'jotai';
import AgentsListSection from '../components/Agents/AgentListSection';
import AgentsTableViewSection from '../components/Agents/AgentsTableViewSection';
import MyTeamContainer from '../components/MyTeam/MyTeamContainer';

import { memo } from 'react';
import { usePermissionContext } from '../context/PermissionsProvider';
import { myTeamViewMode, MyTeamViewModes } from '../utils/jotai';

const MyTeam = () => {
  const [viewMode] = useAtom(myTeamViewMode);
  const isGrid = viewMode === MyTeamViewModes.grid;
  const { getPermission } = usePermissionContext();
  const hasViewAgentsPermission = getPermission('se-view_agents');

  return (
    <MyTeamContainer>
      {hasViewAgentsPermission &&
        (isGrid ? <AgentsListSection /> : <AgentsTableViewSection />)}
    </MyTeamContainer>
  );
};

export default memo(MyTeam);
