import { MultiSelect } from '@xsell/xsell-ui';
import { cn } from '../../utils';

export type SelectOptions<T> = {
  name: string;
  value: T;
};

type MultiSelectFieldProps<T> = {
  name: string;
  placeholder: string;
  label: string;
  disabled?: boolean;
  options: SelectOptions<T>[];
  className?: string;
  value: T[] | null;
  onChange: (value: T[] | null) => void;
};

export default function MultiSelectField<T>({
  name,
  placeholder,
  label,
  disabled,
  options,
  className,
  value,
  onChange,
}: MultiSelectFieldProps<T>) {
  return (
    <div className={cn('flex flex-col gap-y-2', className)}>
      <label htmlFor={name} className="text-sm text-colorGray font-medium">
        {label}
      </label>
      <MultiSelect
        id={name}
        value={value}
        onChange={(e) => onChange(e.value)}
        disabled={disabled}
        options={options}
        optionLabel="name"
        filter
        placeholder={placeholder}
        display="chip"
        className="text-xs"
      />
    </div>
  );
}
