const HeatMapTooltip = ({ cell }: { cell: ConversationChecklistCellProps }) => (
  <div className="flex items-center gap-1 px-2 py-1 whitespace-pre bg-white rounded-sm shadow-sm">
    <span
      className="w-4 h-4 inline-block border-[#dedede] border"
      style={{
        backgroundColor: cell.color,
      }}
    />
    <strong>{cell.serieId} - </strong>
    <span>{cell.data.x}: </span>
    <span>{cell.formattedValue ?? '0%'}</span>
  </div>
);

export default HeatMapTooltip;
