import { WidgetTitle } from '@xsell/xsell-ui';
import { useEffect, useState } from 'react';
import { ExpandIcon } from '../ExpandCollapseIcon/expand-collapse-icon';
import clsx from 'clsx';

const Title = ({
  title,
  titleInfoText,
  expansible,
  setExpandWidget,
}: WidgetCardTitleProps & Partial<ExpandProps>) => {
  return (
    <WidgetTitle
      className="text-colorBlack"
      title={title}
      infoText={titleInfoText}
    >
      {expansible && setExpandWidget && (
        <span
          className={'ml-auto cursor-pointer'}
          onClick={() => expansible && setExpandWidget()}
        >
          <ExpandIcon />
        </span>
      )}
    </WidgetTitle>
  );
};

const withWidgetCard = <T extends object>(
  WrappedComponent: React.ElementType,
  title: string,
  titleInfoText: string
) => {
  const WithWidgetCard = ({
    expansible = false,
    defaultExpanded = false,
    className = 'p-4 overflow-hidden bg-white border-[#f2f3f3] rounded-md drop-shadow-none',
    ...props
  }: WidgetCardProps & T) => {
    const [isExpanded, setIsExpanded] = useState(false);

    useEffect(() => {
      setIsExpanded(defaultExpanded);
    }, [defaultExpanded]);

    const setExpandWidget = () => {
      setIsExpanded((prev) => !prev);
    };

    const expandProps = {
      isExpanded,
      expansible,
      setExpandWidget,
    };

    return (
      <div className={clsx(className, { 'col-span-full': isExpanded })}>
        <Title
          title={title}
          titleInfoText={titleInfoText}
          expansible={expansible}
          setExpandWidget={setExpandWidget}
        />
        <WrappedComponent
          {...(props as T)}
          {...(expansible ? (expandProps as ExpandProps) : {})}
        />
      </div>
    );
  };

  WithWidgetCard.displayName = 'WithWidgetCardHOC';
  return WithWidgetCard;
};

export default withWidgetCard;
