import { RadioButton } from '@xsell/xsell-ui';
import { Controller, useForm } from 'react-hook-form';
import TabButtons from '../TabForm/TabButtons';
import { useGetAgentCount } from '../../hooks/queries';
import { useEffect, useState } from 'react';
import { usePermissionContext } from '../../context/PermissionsProvider';
import InvalidPermissions from '../InvalidPermissions/InvalidPermissions';

const AgentPreferences = () => {
  const { data: agentCount } = useGetAgentCount();
  const defaultValues: Record<'value', RadioValues> = { value: 'voice' };
  const { control, getValues } = useForm({ defaultValues });

  const [agentType, setAgentType] = useState<Record<RadioValues, number>>({
    chat: 0,
    voice: 0,
    both: 0,
  });

  function agentTypeCount() {
    const count = 0;
    agentCount?.forEach(({ label, count }) => {
      setAgentType((previous) => ({
        ...previous,
        [label]: count,
      }));
    });

    return count;
  }

  useEffect(() => {
    if (agentCount?.length) {
      agentTypeCount();
    }
  }, [agentCount]);

  const { getPermission } = usePermissionContext();
  const hasEditPreferencePermission = getPermission('se-update_settings');

  return (
    <>
      {' '}
      {hasEditPreferencePermission ? (
        <div className="flex flex-col h-full text-colorGray">
          <h2 className="text-xl font-medium">Agent Preference</h2>
          <h3 className="text-sm">
            Select which agent type you&apos;d like to see data displayed for in
            widgets
          </h3>
          <Controller
            name="value"
            control={control}
            rules={{ required: 'Value is required.' }}
            render={({ field }) => (
              <div className="flex flex-col gap-2 mt-3">
                <div>
                  <RadioButton
                    inputId="voice"
                    {...field}
                    inputRef={field.ref}
                    value={'voice'}
                    checked={field.value === 'voice'}
                  />
                  <label htmlFor="voice" className="ml-2 cursor-pointer">
                    Voice {agentType.voice}
                  </label>
                </div>
                <div>
                  <RadioButton
                    inputId="chat"
                    {...field}
                    inputRef={field.ref}
                    value={'chat'}
                    checked={field.value === 'chat'}
                  />
                  <label htmlFor="chat" className="ml-2 cursor-pointer">
                    Chat {agentType.chat}
                  </label>
                </div>
                <div>
                  <RadioButton
                    inputId="both"
                    {...field}
                    inputRef={field.ref}
                    value={'both'}
                    checked={field.value === 'both'}
                  />
                  <label htmlFor="both" className="ml-2 cursor-pointer">
                    Both {agentType.both}
                  </label>
                </div>
              </div>
            )}
          />
          <div className="self-end">
            <TabButtons onSubmit={getValues} />
          </div>
        </div>
      ) : (
        <InvalidPermissions type="component" />
      )}
    </>
  );
};

export default AgentPreferences;
