import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { Button } from '@xsell/xsell-ui';
import { Link } from 'react-scroll';
import { useGetAgentRecommendations } from '../../../hooks/queries/conversationChecklist';
import GenericLoader from '../../GenericLoader';
import InfoMessage from '../utils/InfoMessage';
import withDialog from '../withDialog';

import { ComputedCell, HeatMapDatum } from '@nivo/heatmap';

interface AgentProfileDialogProps {
  agentExternalId: string;
  handleClose(): void;
}

const text =
  'Checklist Usage for <strong>Motivation</strong> falls below the 80% threshold set';

const MultiProgressBar = ({
  pct_use: pctUse,
  agent_pct_use: agentPctUse,
  hiper_pct_use: hiperPctUse,
  rec_shown_name: recShownName,
}: GetAgentRecommendations) => {
  return (
    <div className="grid items-center py-1 px-3 border-[1px] rounded-md grid-cols-agentInfo border-[#DFDFDF] gap-4">
      <span className="max-w-[10rem] overflow-hidden text-lg font-medium text-colorGray text-ellipsis whitespace-nowrap">
        {recShownName}
      </span>
      <span
        className="justify-self-end bg-[#EDEDED] h-2 rounded-md w-full overflow-hidden"
        title={recShownName}
      >
        <span
          style={{
            backgroundColor: '#2a84fa',
            display: 'inline-block',
            width: `${(hiperPctUse * 100).toFixed(0)}%`,
          }}
        >
          &nbsp;
        </span>
        <span
          style={{
            backgroundColor: '#22F5B5',
            display: 'inline-block',
            width: `${(agentPctUse * 100).toFixed(0)}%`,
          }}
        >
          &nbsp;
        </span>
      </span>
      <span className="justify-self-center">{(pctUse * 100).toFixed(1)}%</span>
    </div>
  );
};

const AgentProfileDialog = ({
  agentExternalId,
  serieId,
  handleClose,
  x,
}: ComputedCell<HeatMapDatum> & AgentProfileDialogProps) => {
  const {
    data = [],
    isLoading,
    isError,
  } = useGetAgentRecommendations(new Date(x), agentExternalId, serieId);

  if (isLoading || isError || !data?.length) {
    return (
      <GenericLoader
        isLoading={isLoading}
        isError={isError}
        noData={!data.length}
      />
    );
  }

  return (
    <div className="flex flex-col gap-2">
      <section className="flex flex-col gap-2 overflow-auto max-h-96">
        {data.map((props) => (
          <MultiProgressBar {...props} key={props.rec_shown_name} />
        ))}
      </section>
      <div className="w-full mb-1 border-b" />
      <InfoMessage text={text} />
      <div className="flex items-center justify-between text-white">
        <Button className="h-8 bg-colorBlue">Create a Coaching Plan</Button>
        <div className="flex items-center gap-2">
          <Icon icon={faPhone} className="text-colorBlue" />
          <span className="font-semibold text-md text-colorBlue" role="button">
            <Link
              activeClass="active"
              to="engagements"
              duration={1000}
              onClick={handleClose}
              isDynamic
              spy
              smooth
            >
              View Calls
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default withDialog(AgentProfileDialog);
