import {
  Cell,
  Legend,
  Pie,
  PieChart,
  PieLabel,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import { ContentType } from 'recharts/types/component/Tooltip';
import { useGetLoginSummary } from '../../hooks/queries';
import { cn, formatNumber } from '../../utils';
import GenericLoader from '../GenericLoader';

const COLORS = ['#EBE6FF', '#4A1DFB'];
const RADIAN = Math.PI / 180;
const renderCustomizedLabel: PieLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 1.7;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  const xOffset = x > cx ? 3 : -3;

  return (
    <text
      x={x + xOffset}
      y={y}
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
      className="text-xs text-slate-800"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const CustomTooltip: ContentType<number, string> = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div className="flex items-center p-2 text-white bg-gray-700 rounded gap-x-2">
        <div
          className={cn(
            'w-3 h-3',
            payload[0].payload.name === 'logged-in'
              ? 'bg-colorBlue'
              : 'bg-[#EBE6FF]'
          )}
        />
        <p className="text-[10px] font-medium">{`${formatNumber(
          payload[0].value ?? 0
        )} Calls`}</p>
      </div>
    );
  }

  return null;
};

const renderLegend = () => {
  return (
    <div className="flex flex-row justify-center pt-5 gap-x-4">
      <div className="flex flex-col items-center gap-y-1">
        <div className="h-3 rounded-full w-7 bg-colorBlue" />
        <span className="text-[10px]">Logged In</span>
      </div>
      <div className="flex flex-col items-center gap-y-1">
        <div className="w-7 h-3 rounded-full bg-[#EBE6FF]" />
        <span className="text-[10px]">Logged Out</span>
      </div>
    </div>
  );
};

export default function LoginSummaryWidget({
  apiSearchParams,
}: BaseQueryProps) {
  const { data, isLoading, isError } = useGetLoginSummary(apiSearchParams);

  if (isLoading || isError || !data || data?.totalCalls === 0) {
    return (
      <GenericLoader
        isLoading={isLoading}
        isError={isError}
        noData={!data || data?.totalCalls === 0}
      />
    );
  }

  const { loggedInCalls, totalCalls } = data;
  const loggedOutCalls = totalCalls - loggedInCalls;

  const chartData = [
    { name: 'logged-out', value: loggedOutCalls },
    { name: 'logged-in', value: loggedInCalls },
  ];

  let angleShift = 0;
  const percentLoggedIn = (loggedInCalls * 100) / totalCalls;
  if (percentLoggedIn <= 20 || percentLoggedIn >= 80) {
    angleShift = -90;
  }

  return (
    <div className="h-60">
      <ResponsiveContainer width="100%" height="100%">
        <PieChart>
          <Pie
            isAnimationActive={false}
            dataKey="value"
            data={chartData}
            startAngle={90 - angleShift}
            endAngle={-360 + angleShift}
            innerRadius="60%"
            outerRadius="90%"
            fill="#82ca9d"
            label={renderCustomizedLabel}
            labelLine={{
              strokeDasharray: '2 2',
              stroke: '#21354C',
            }}
          >
            {chartData.map((entry, index) => (
              <Cell
                key={`cell-${entry.name}-${entry.value}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Tooltip content={CustomTooltip} />
          <Legend content={renderLegend} />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
}
