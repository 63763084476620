import { format, addDays } from 'date-fns';

const leaderColors: Record<colorsIndexes, string> = {
  '0': '#C6FFDF',
  '1': '#C6FFE0',
  '2': '#DCFBEA',
};

const coachingSpotlightColors: Record<colorsIndexes, string> = {
  '0': '#FF8E8E',
  '1': '#FF8E8E',
  '2': '#FBB',
};

const formatHeatMapData = (
  heatMapData: ConversationChecklist[],
  isSingleDate?: boolean,
  isExpanded?: boolean
) =>
  heatMapData.map(({ data, id }) => ({
    id,
    data: data?.map(({ x, y }) => {
      const date = new Date(x);
      const formattedDate = format(date, 'MM/dd');
      const addedDate = format(addDays(date, 6), 'MM/dd');

      const dateLabel =
        isSingleDate && !isExpanded
          ? `${formattedDate}`
          : `${formattedDate} - ${addedDate}`;

      return {
        x: dateLabel,
        y,
      };
    }),
  }));

const title = 'Conversation Checklist';
const infoText =
  'This widget gives actionable insights based on recommendations purpose, intent and expected outcome. The Heatmap represents the % (Percentage) of the engagements that used that Talking point or recommendation relative to the rest of the talking points.';

export {
  leaderColors,
  coachingSpotlightColors,
  formatHeatMapData,
  title,
  infoText,
};
