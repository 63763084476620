import HiperCoachContainer from '../components/HiperCoach/HiperCoachContainer';
import MyTeamCounter from '../components/MyTeam/MyTeamCounter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './page.css';
import { faBookmark } from '@fortawesome/free-solid-svg-icons';

const bookMarkIcon = (
  <FontAwesomeIcon icon={faBookmark} className="text-colorBlue" width={24} />
);

export default function HiperCoach() {
  return (
    <HiperCoachContainer>
      <div className="flex flex-row flex-wrap mb-8 gap-y-4 gap-x-4">
        <MyTeamCounter count="10" title="Active Coaching Plans" />
        <MyTeamCounter
          count="3"
          title="Saved Engagements"
          icon={bookMarkIcon}
        />
        <MyTeamCounter count="13" title="Coaching Opportunities" />
      </div>
    </HiperCoachContainer>
  );
}
