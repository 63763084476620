import { useGetConversationChecklistForEngagement } from '../../../hooks/queries/conversationChecklist';
import GenericLoader from '../../GenericLoader';
import withWidgetCard from '../../withWidgetCard';
import { formatHeatMapData } from '../utils/utils';
import Presentation from './EngagementConversationChecklist';

const title = 'Conversation Checklist';
const infoText =
  'This widget gives actionable insights based on recommendations purpose, intent and expected outcome. The Heatmap represents the % (Percentage) of the engagements that used that Talking point or recommendation relative to the rest of the talking points.';

interface EngagementAnalyticsConversationChecklistProps {
  engagementId: string;
}

const EngagementConversationChecklist = ({
  engagementId,
}: EngagementAnalyticsConversationChecklistProps) => {
  const { data, isLoading, isError } =
    useGetConversationChecklistForEngagement(engagementId);

  if (!data?.length || isLoading || isError) {
    return (
      <GenericLoader
        isError={isError}
        isLoading={isLoading}
        noData={!data?.length}
      />
    );
  }

  const formattedHeatMapData = formatHeatMapData(data);

  return <Presentation data={formattedHeatMapData} />;
};

export default withWidgetCard<EngagementAnalyticsConversationChecklistProps>(
  EngagementConversationChecklist,
  title,
  infoText
);
