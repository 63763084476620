import { useAtom } from 'jotai';
import { SelectButton, SelectButtonChangeEvent } from '@xsell/xsell-ui';
import { useCallback } from 'react';
import { MyTeamViewModes, myTeamViewMode } from '../utils/jotai';

type SelectOption = { icon: string; value: MyTeamViewModes };

const justifyOptions: SelectOption[] = [
  { icon: 'pi pi-th-large', value: MyTeamViewModes.grid },
  { icon: 'pi pi-list', value: MyTeamViewModes.list },
];

const justifyTemplate = (option: SelectOption) => {
  return <i className={option.icon}></i>;
};

export default function SelectMyTeamViewMode() {
  const [value, setValue] = useAtom(myTeamViewMode);

  const handleChange = useCallback(
    (e: SelectButtonChangeEvent) => {
      setValue(e.target.value);
    },
    [setValue]
  );

  return (
    <div className="flex justify-end justify-content-center">
      <SelectButton
        value={value}
        onChange={handleChange}
        itemTemplate={justifyTemplate}
        optionLabel="value"
        options={justifyOptions}
        unselectable={false}
      />
    </div>
  );
}
