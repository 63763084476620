import { DataTable } from '@xsell/xsell-ui';
import withToggleTab from '../CollapsibleTab/withToggleTab';

const TrainingModules = () => {
  return (
    <DataTable
      value={undefined}
      alwaysShowPaginator={false}
      responsiveLayout="scroll"
      emptyMessage="No information found for Training Modules"
    />
  );
};

export default withToggleTab(TrainingModules);
