import { FunctionComponent, useMemo, Fragment } from 'react';
import { useMatches, useParams } from 'react-router-dom';

type ReactRouterHandle = {
  crumb: FunctionComponent<any>;
};

export default function Breadcrumbs() {
  const matches = useMatches();
  const params = useParams();
  const crumbs = useMemo(() => {
    const crumbsElements = matches
      // first get rid of any matches that don't have handle and crumb
      .filter((match) => Boolean((match.handle as ReactRouterHandle)?.crumb))
      // now map them into an array of elements, passing the loader
      // data to each one
      .map((match) =>
        (match.handle as ReactRouterHandle).crumb(match.data, params)
      );

    return crumbsElements;
  }, [matches, params]);

  const crumbsDepth = crumbs.length;

  return (
    <ol className="flex flex-row gap-x-2 items-center">
      {crumbs.map((crumb, index) => (
        <Fragment key={`crumb-${crumb?.key}`}>
          <li className="text-base flex flex-row gap-x-2">{crumb}</li>
          {crumbsDepth === 1 || index < crumbsDepth - 1 ? (
            <span
              className="text-base text-colorGray font-bold"
              key={`crumb-divider-${crumb?.key}`}
            >
              /
            </span>
          ) : null}
        </Fragment>
      ))}
    </ol>
  );
}
