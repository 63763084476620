interface InfoMessageProps {
  text: string;
}

const InfoMessage = ({ text }: InfoMessageProps) => (
  <div className="grid items-center px-1 py-2 text-xs rounded-md bg-violet-100 grid-cols-conversationChecklist">
    <i className="text-lg text-[#4a1dfb] justify-self-center pi pi-info-circle" />
    <span
      className="text-indigo-600 h-fit"
      dangerouslySetInnerHTML={{ __html: text }}
    />
  </div>
);

export default InfoMessage;
