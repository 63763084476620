import { useAtom } from 'jotai';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { navOpened } from '../../utils/jotai';
import Button from '../ui/Button';
import { BACKEND_ROUTES } from '../../services/api';
import { cn } from '../../utils';

export default function LogoutButton() {
  const [isNavOpened] = useAtom(navOpened);

  return (
    <Button
      aria-label="Log out"
      className={cn(
        'rounded-lg flex flex-row gap-x-4 items-center hover:text-colorBlack text-colorLightGray',
        !isNavOpened && 'justify-center'
      )}
      variant="ghost"
      onClick={() => {
        window.location.replace(BACKEND_ROUTES.logout);
      }}
    >
      <FontAwesomeIcon icon={faArrowRightFromBracket} size="1x" />
      {isNavOpened ? (
        <span className="shrink-0 animate-fadeIn">Log out</span>
      ) : null}
    </Button>
  );
}
