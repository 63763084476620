import { memo } from 'react';
import CircularTreemapPresentation from './CircularTreemapPresentation';
import { useGetTopicBreakdown } from '../../../hooks/queries';
import GenericLoader from '../../GenericLoader';

const CircularTreemapBase = ({ apiSearchParams }: BaseQueryProps) => {
  const { data, isLoading, isError } = useGetTopicBreakdown(apiSearchParams);

  if (isLoading || isError || !data) {
    return (
      <GenericLoader isLoading={isLoading} isError={isError} noData={!data} />
    );
  }

  return <CircularTreemapPresentation data={data} />;
};

export default memo(CircularTreemapBase);
