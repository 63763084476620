import { useQuery } from '@tanstack/react-query';
import {
  getTopFiveTopics,
  getCallBreakdownData,
  getLeaderboard,
  getTotalCalls,
  getTopicBreakdown,
  getLoginSummary,
  getOnlineAgentActivity,
  getDashboardFilterOptions,
  getTogglzFeatures,
  getAgentCount,
} from '../../services/service';
import Cookies from 'js-cookie';
import { api } from '../../services/api';

type WidgetDateSummaryParams = Omit<APISearchParams, keyof FilterCriteria>;

export function useGetCallBreakdown(queryParams: APISearchParams) {
  const { data: userData } = useGetCurrentUser();
  const tenantId = userData?.attributes.tenantPublicId[0];

  const query = useQuery<CallBreakdownType>({
    queryKey: ['callBreakdown', queryParams],
    queryFn: () => getCallBreakdownData(queryParams),
    enabled: !!tenantId,
  });

  return query;
}

export function useGetTopFiveTopics(queryParams: APISearchParams) {
  const { data: userData } = useGetCurrentUser();
  const tenantId = userData?.attributes.tenantPublicId[0];

  const query = useQuery<TopFiveTopicsReponse>({
    queryKey: ['topFiveTopics', queryParams],
    queryFn: () => getTopFiveTopics(queryParams),
    enabled: !!tenantId,
  });

  return query;
}

export function useGetLeaderboard(queryParams?: WidgetDateSummaryParams) {
  const { data: userData } = useGetCurrentUser();
  const tenantId = userData?.attributes.tenantPublicId[0];

  const query = useQuery<LeaderboardResponse>({
    queryKey: ['leaderboard', queryParams],
    queryFn: () => getLeaderboard(tenantId!, queryParams),
    enabled: !!tenantId,
  });

  return query;
}
export function useGetTotalCalls(queryParams?: WidgetDateSummaryParams) {
  const { data: userData } = useGetCurrentUser();
  const tenantId = userData?.attributes.tenantPublicId[0];
  const query = useQuery({
    queryKey: ['totalCalls', queryParams],
    queryFn: () => getTotalCalls(queryParams),
    enabled: !!tenantId,
  });
  return query;
}

export function useGetTopicBreakdown(queryParams?: APISearchParams) {
  const { data: userData } = useGetCurrentUser();
  const tenantId = userData?.attributes.tenantPublicId[0];

  const query = useQuery<TopicBreakdownResponse | null>({
    queryKey: ['topicTreeMap', queryParams],
    queryFn: () => getTopicBreakdown(queryParams),
    enabled: !!tenantId,
  });

  return query;
}

export function useGetLoginSummary(queryParams?: WidgetDateSummaryParams) {
  const { data: userData } = useGetCurrentUser();
  const tenantId = userData?.attributes.tenantPublicId[0];

  const query = useQuery<LoginSummaryResponse>({
    queryKey: ['loginSummary', queryParams],
    queryFn: () => getLoginSummary(queryParams),
    enabled: !!tenantId,
  });

  return query;
}

export function useGetOnlineAgentActivity() {
  const { data: userData } = useGetCurrentUser();
  const tenantId = userData?.attributes.tenantPublicId[0];

  const query = useQuery<OnlineAgentActivityResponse>({
    queryFn: () => getOnlineAgentActivity(),
    enabled: !!tenantId,
  });
  return query;
}

export function useGetCurrentUser() {
  const query = useQuery({
    queryKey: ['currentUser'],
    queryFn: async () => {
      if (Cookies.get('XSRF-TOKEN')) {
        const response = await api.get<User>('/api/oauth/whoami');
        if (!response || !response.data) {
          throw new Error('Failed to retrieve user data');
        }
        api.defaults.headers.common[
          'tenant'
        ] = `${response?.data.attributes.tenantPublicId[0]}`;
        return response.data;
      } else {
        throw new Error('Cookie not available');
      }
    },
    onError: () => {
      window.location.href = process.env.REACT_APP_PLATFORM_URL ?? '';
    },
    retry: 1,
  });

  return query;
}

export function useGetDashboardFilterOptions() {
  const { data: userData } = useGetCurrentUser();
  const tenantId = userData?.attributes.tenantPublicId[0];

  const query = useQuery({
    queryKey: ['dashboardFilterOptions'],
    queryFn: () => getDashboardFilterOptions(),
    enabled: !!tenantId,
  });

  return query;
}

export function useGetTogglzFeatures() {
  const { data: userData } = useGetCurrentUser();
  const tenantId = userData?.attributes.tenantPublicId[0];

  const query = useQuery<TogglzFeaturesDTO[]>({
    queryKey: ['togglzFeatures'],
    queryFn: () => getTogglzFeatures(),
    enabled: !!tenantId,
  });

  return query;
}

export function useGetAgentCount() {
  const { data: userData } = useGetCurrentUser();
  const tenantId = userData?.attributes.tenantPublicId[0];
  const managerName =
    userData?.attributes.firstName.toUpperCase() +
    ' ' +
    userData?.attributes.lastName.toUpperCase();

  const query = useQuery<AgentCountDTO[]>({
    queryKey: ['agentCount'],
    queryFn: () => getAgentCount(managerName),
    enabled: !!tenantId,
  });

  return query;
}
