import { useQuery } from '@tanstack/react-query';
import { useGetCurrentUser } from '.';
import {
  getAgentByLogin,
  getAgentEngagementDetails,
  getSingleEngagementDetail,
  getEngagementSummary,
  getEngagementTranscript,
  getFilteredAgents,
  getProgramList,
  getSupervisorList,
} from '../../services/service';

export function useCurrentUserTenantId() {
  const { data: userData } = useGetCurrentUser();
  return userData?.attributes.tenantPublicId[0];
}

export function useGetAgentByLogin(login: string) {
  const tenantId = useCurrentUserTenantId();

  const query = useQuery({
    queryKey: ['myTeam', 'agentById', login],
    queryFn: () => getAgentByLogin(login),
    enabled: !!tenantId,
  });

  return query;
}

export function useGetEngagements(agentExternalId: string) {
  const tenantId = useCurrentUserTenantId();

  const query = useQuery<EngagementDetail[]>({
    queryKey: ['myTeam', 'engagements', agentExternalId],
    queryFn: () => getAgentEngagementDetails(agentExternalId.toUpperCase()),
    enabled: !!tenantId && !!agentExternalId,
  });

  return query;
}

export function useGetEngagementTranscript(
  engagementId: string,
  startDate: string
) {
  const tenantId = useCurrentUserTenantId();

  const query = useQuery<EngagementTranscript[]>({
    queryKey: ['myTeam', 'engagements', 'transcripts', engagementId],
    queryFn: () => getEngagementTranscript(engagementId, startDate),
    enabled: !!tenantId,
  });

  return query;
}

export function useGetFilteredAgents() {
  const tenantId = useCurrentUserTenantId();

  const query = useQuery({
    queryKey: ['myTeam', 'agents', 'list'],
    queryFn: () => getFilteredAgents(),
    enabled: !!tenantId,
  });

  return query;
}

export function useGetProgramList() {
  const tenantId = useCurrentUserTenantId();

  const query = useQuery<AgentFetchResponse<AgentProgram>>({
    queryKey: ['myTeam', 'agents', 'program-list'],
    queryFn: () => getProgramList(tenantId!),
    enabled: !!tenantId,
  });

  return query;
}

export function useGetSupervisorList() {
  const tenantId = useCurrentUserTenantId();

  const query = useQuery<AgentFetchResponse<AgentSupervisor>>({
    queryKey: ['myTeam', 'agents', 'supervisor-list'],
    queryFn: () => getSupervisorList(tenantId!),
    enabled: !!tenantId,
  });

  return query;
}

export function useGetEngagementDetail(
  engagementId: string,
  agentExternalId: string,
  enabled = true
) {
  const tenantId = useCurrentUserTenantId();

  const query = useQuery({
    queryKey: ['myTeam', 'engagements', 'detail', engagementId],
    queryFn: () => getSingleEngagementDetail(engagementId, agentExternalId),
    enabled: !!tenantId && !!engagementId && !!agentExternalId && enabled,
  });

  return query;
}

export function useGetEngagementSummary(engagementId: string, enabled = true) {
  const tenantId = useCurrentUserTenantId();

  const query = useQuery({
    queryKey: ['myTeam', 'engagements', 'summary', engagementId],
    queryFn: () => getEngagementSummary(engagementId),
    enabled: !!tenantId && enabled,
  });

  return query;
}
