import { HeatMap } from '@xsell/xsell-ui';
import { ComponentProps } from 'react';

const textColor = '#21354C';
const heatMapColors = ['#FFF', '#2a06d4'];
const heatMapProps = {
  valueFormat: '^-.1%',
  labelTextColor: textColor,
  theme: { textColor },
  emptyColor: 'white',
  colors: {
    type: 'sequential',
    colors: heatMapColors,
    minValue: 0,
    maxValue: 1,
  },
  enableLabels: false,
} as ComponentProps<typeof HeatMap>;

export { textColor, heatMapColors, heatMapProps };
