interface TabFormItemProps {
  title: string;
  Content: React.ElementType;
}

const TabFormContent = ({ Content }: TabFormItemProps) => {
  return (
    <div className="h-full m-1">
      <Content />
    </div>
  );
};
export default TabFormContent;
