import * as AvatarPrimitives from '@radix-ui/react-avatar';
import { memo, useMemo } from 'react';
import { cn } from '../../utils';

type AvatarProps = {
  agentId: string;
  image?: string;
  firstName: string;
  lastName: string;
};

function getInitials(firstName: string, lastName: string) {
  return `${firstName.charAt(0).toUpperCase()}${lastName
    .charAt(0)
    .toUpperCase()}`;
}

export function getAgentColor(agent: string) {
  const colors = ['#FFB624', '#22F5B5', '#7F1DFB', '#1D4EFB', '#FF176B'];
  let hash = 0;

  if (agent.length === 0) return colors[hash];
  for (let i = 0; i < agent.length; i++) {
    hash = agent.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash;
  }
  hash = ((hash % colors.length) + colors.length) % colors.length;

  return colors[hash];
}

const Avatar = ({ agentId, image, firstName, lastName }: AvatarProps) => {
  const themeColor = useMemo(() => {
    return getAgentColor(agentId + firstName + lastName);
  }, [agentId, firstName, lastName]);

  return (
    <AvatarPrimitives.Root
      className={cn(
        'rounded-full flex justify-center items-center w-28 h-28',
        !themeColor && 'bg-emerald-400'
      )}
      style={{
        backgroundColor: themeColor,
      }}
    >
      <AvatarPrimitives.Image
        className="object-cover w-full h-full rounded-full"
        src={image}
        alt={`${firstName} ${lastName}`}
      />
      <AvatarPrimitives.Fallback
        className="flex items-center justify-center w-full h-full text-5xl font-medium text-white"
        delayMs={600}
      >
        {getInitials(firstName, lastName)}
      </AvatarPrimitives.Fallback>
    </AvatarPrimitives.Root>
  );
};

export default memo(Avatar);
