import { Dialog } from '@xsell/xsell-ui';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import notebook from '../../assets/notebook.png';
import {
  useGetEngagementDetail,
  useGetEngagementSummary,
} from '../../hooks/queries/MyTeam';
import { engagementLinkBuilder } from '../../utils/agent';
import Divider from '../Divider';
import EngagementDetailsSection from '../EngagementDetail/engagement-details-section';
import EngagementSummarySection from '../EngagementDetail/engagement-summary-section';

interface QuickViewModalProps {
  engagementId: string;
  agent: AgentData;
  timestamp: string;
}

const header = (
  <h4 className="text-lg font-bold text-colorGray">Engagement Details</h4>
);

const QuickViewModal = ({
  engagementId,
  agent,
  timestamp,
}: QuickViewModalProps) => {
  const [showDialog, setShowDialog] = useState(false);

  const {
    data: detail,
    isLoading: isDetailLoading,
    refetch,
  } = useGetEngagementDetail(engagementId, agent.agentExternalId, showDialog);

  const { data: summary, isLoading: isSummaryLoading } =
    useGetEngagementSummary(engagementId, showDialog);

  const toggleDialog = () => {
    setShowDialog(!showDialog);
  };

  const footer = useMemo(
    () => (
      <div className="flex justify-center">
        <Link to={engagementLinkBuilder(timestamp, engagementId)}>
          <span className="px-6 py-1 text-sm font-medium text-blue-500 bg-blue-100 rounded-md">
            View full summary
          </span>
        </Link>
      </div>
    ),
    [engagementId, timestamp]
  );

  const handleShowDialog = () => {
    refetch();
  };

  return (
    <>
      <img
        className="m-auto cursor-pointer"
        src={notebook}
        alt="Quick View"
        onClick={toggleDialog}
      />

      <Dialog
        onShow={handleShowDialog}
        header={header}
        visible={showDialog}
        onHide={toggleDialog}
        displayCancelButton={false}
        displayConfirmButton={false}
        displayCloseIcon
        footer={footer}
      >
        <EngagementDetailsSection
          detail={detail}
          agent={agent}
          isLoading={isDetailLoading}
        />
        <Divider />
        <EngagementSummarySection
          summary={summary}
          isLoading={isSummaryLoading}
        />
      </Dialog>
    </>
  );
};

export default QuickViewModal;
