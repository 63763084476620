import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDay, faXmark } from '@fortawesome/free-solid-svg-icons';
import * as Popover from '@radix-ui/react-popover';
import { useState } from 'react';
import DateRangePicker from './DateRangePicker';
import { format } from 'date-fns';
import { cn } from '../../utils';

export default function DateRangePickerInput({
  inputValues,
  setInputValues,
  onClear,
}: DateRangePickerInputProps) {
  const [open, setOpen] = useState(false);
  const [selectedRange, setSelectedRange] = useState<DateRange>('today');

  function handleSelectedRange(dateRange: DateRange) {
    setSelectedRange(dateRange);
  }

  const onApply = (value: DateRangeInputData) => {
    setInputValues(value);
    setOpen(false);
  };

  const dateRangeStringValue = inputValues.dates
    .filter(Boolean)
    .map((date) => (date ? format(date, 'MM/dd/yyyy') : null))
    .join(' - ');

  return (
    <div className="font-medium bg-white">
      <Popover.Root open={open} onOpenChange={(status) => setOpen(status)}>
        <Popover.Trigger className="PopoverTrigger">
          <Input
            type="text"
            className="w-[330px]"
            value={dateRangeStringValue}
            onClick={() => {
              setOpen(true);
            }}
            onClear={onClear}
          />
        </Popover.Trigger>
        <Popover.Portal>
          <Popover.Content
            className="animate-in data-[side=bottom]:slide-in-from-top-2 data-[side=top]:slide-in-from-bottom-2 data-[side=right]:slide-in-from-left-2 data-[side=left]:slide-in-from-right-2 z-50 rounded-md border border-slate-100 bg-white p-4 shadow-md outline-none"
            sideOffset={5}
          >
            <DateRangePicker
              onApply={onApply}
              selectedRange={selectedRange}
              setSelectedRange={handleSelectedRange}
            />
            <Popover.Arrow className="fill-white" />
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </div>
  );
}

type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  onClear?: () => void;
  className?: string;
};

function Input({ onClear, className, ...props }: InputProps) {
  return (
    <div className="relative inline-block">
      <FontAwesomeIcon
        icon={faCalendarDay}
        className="absolute text-colorLightGray left-2 top-1/2 -mt-[0.625rem]"
        size="lg"
      />
      <input
        contentEditable={false}
        className={cn(
          'h-10 w-auto rounded-md border bg-white border-slate-300 bg-transparent py-2 pl-9 text-sm placeholder:text-slate-400 focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
          className
        )}
        value="03/01/2023 - 03/09/2023"
        {...props}
      />
      <FontAwesomeIcon
        icon={faXmark}
        className="absolute text-colorLightGray right-2 top-1/2 -mt-[0.625rem]"
        size="lg"
        onClick={onClear}
      />
    </div>
  );
}
