/* eslint-disable @typescript-eslint/no-empty-function */
import { PaginatorPageChangeEvent } from '@xsell/xsell-ui';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  useCurrentUserTenantId,
  useGetFilteredAgents,
  useGetProgramList,
  useGetSupervisorList,
} from '../hooks/queries/MyTeam';
import {
  addAgentToFavorites,
  getFilteredAgents,
  removeAgentFromFavorites,
} from '../services/service';
import { useAlertMessage } from './MessageProvider';

type Children = { children?: React.ReactNode };
type LoadingCallBackFn = (isLoading: boolean) => void;

interface AgentGroups {
  agentList: AgentSearchApiResponse | null;
  handleTablePaginatorCallback: (
    props: Partial<PaginatorPageChangeEvent>,
    filters: string,
    cb: LoadingCallBackFn
  ) => void;
  toggleAgentWatchList: (
    agentId: string,
    willBeWatchListed: boolean,
    alertMessage: AlertMessageParams
  ) => void;
  programList: AgentFetchResponse<AgentProgram>;
  supervisorList: AgentFetchResponse<AgentSupervisor>;
}

const AgentsListContext = createContext<AgentGroups | null>(null);

AgentsListContext.displayName = 'AgentWatchListContext';

const NETWORK_ERROR_MESSAGE =
  'Could not complete the request, please try again later.';

export const useAgentContext = () => {
  const context = useContext(AgentsListContext);

  if (!context) {
    throw new Error(
      'useAgentContext hook must be used within the AgentWatchListProvider.'
    );
  }

  return context;
};

const AgentsListProviderWrapper = (children: Children) => {
  const [agentList, setAgentList] = useState<AgentSearchApiResponse>({
    lastPage: -1,
    totalCount: 0,
    content: [],
  });
  const tenantId = useCurrentUserTenantId();
  const { data, isFetched } = useGetFilteredAgents();
  const { data: programList = { content: [] } } = useGetProgramList();
  const { data: supervisorList = { content: [] } } = useGetSupervisorList();
  const { setMessageData } = useAlertMessage();

  const toggleAgentWatchList = useCallback(
    (
      login: string,
      willBeWatchListed: boolean,
      { message, severity }: AlertMessageParams
    ) => {
      const callAction = willBeWatchListed
        ? addAgentToFavorites
        : removeAgentFromFavorites;

      const agentIndex = agentList?.content.findIndex(
        (agent) => agent.login === login
      );

      if (agentIndex >= 0 && tenantId) {
        const watchListAgent = agentList?.content[agentIndex];
        if (watchListAgent?.id) {
          callAction(tenantId, watchListAgent.id)
            .then(() => {
              watchListAgent.watchList = willBeWatchListed;

              setMessageData(message, severity);
              setAgentList((prev) => {
                const updatedList = { ...prev };
                updatedList.content[agentIndex].watchList = willBeWatchListed;

                return updatedList;
              });
            })
            .catch(() => {
              setMessageData(NETWORK_ERROR_MESSAGE, 'error');
            });
        }
      }
    },
    [agentList, setMessageData, tenantId]
  );

  const handleTablePaginatorCallback = useCallback(
    (
      { rows, page }: Partial<PaginatorPageChangeEvent>,
      filters: string,
      cb: LoadingCallBackFn
    ) => {
      cb(true);
      getFilteredAgents(filters, rows, page)
        .then(setAgentList)
        .catch(() => {
          setMessageData(NETWORK_ERROR_MESSAGE, 'error');
        })
        .finally(() => {
          cb(false);
        });
    },
    [setMessageData]
  );

  const value: AgentGroups = useMemo(
    () => ({
      agentList,
      programList,
      supervisorList,
      toggleAgentWatchList,
      handleTablePaginatorCallback,
    }),
    [
      agentList,
      programList,
      supervisorList,
      toggleAgentWatchList,
      handleTablePaginatorCallback,
    ]
  );

  useEffect(() => {
    if (isFetched && data) {
      setAgentList(data);
    }
  }, [data, isFetched]);

  return <AgentsListContext.Provider {...children} value={value} />;
};

const AgentListProvider = ({ children }: Children) => (
  <AgentsListProviderWrapper>{children}</AgentsListProviderWrapper>
);

export default AgentListProvider;
