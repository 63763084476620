import {
  faCircleXmark,
  faTriangleExclamation,
  faCheckCircle,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement } from 'react';

const OUTCOME: Record<SummaryOutcome, string> = {
  resolved: 'Resolved',
  unresolved: 'Unresolved',
  transfered: 'Transfered',
};
const ICONS: Record<SummaryOutcome, ReactElement> = {
  resolved: (
    <FontAwesomeIcon
      icon={faCheckCircle}
      className="text-colorGreen mr-2 max-w-[20px]"
      size="2x"
    />
  ),
  unresolved: (
    <FontAwesomeIcon
      icon={faCircleXmark}
      className="text-colorRed mr-2 max-w-[20px]"
      size="2x"
    />
  ),
  transfered: (
    <FontAwesomeIcon
      icon={faTriangleExclamation}
      className="text-colorOrange mr-2 max-w-[20px]"
      size="2x"
    />
  ),
};

export default function EngagementSummarySection({
  summary,
  isLoading,
}: {
  summary?: EngagementSummary;
  isLoading: boolean;
}) {
  if (isLoading) {
    return (
      <div className="flex flex-col items-center w-full p-5 gap-y-2">
        <FontAwesomeIcon
          icon={faSpinner}
          className="text-colorGray animate-spin"
          size="2x"
        />
        <p>Loading summary</p>
      </div>
    );
  }

  return (
    <div className="py-4 pl-6 pr-8 text-colorBlack">
      <h4 className="text-lg font-bold text-colorBlack">Engagement Summary</h4>
      <section className="py-2 mb-1 border-b">
        <h5 className="text-sm font-bold">Customer Details</h5>
        <p className="text-xs">
          <span className="font-bold">Name: </span>
          {summary?.customer_name ?? 'Default'}
        </p>
      </section>
      <section className="py-2 mb-1 border-b">
        <h5 className="text-sm font-bold">Reason for call</h5>
        <p className="text-xs">
          <span className="font-bold">Intent: </span>
          {summary?.customer_intent ?? '-'}
        </p>
        <p className="text-xs">
          <span className="font-bold">Topic(s): </span>
          <span className="font-bold text-colorBlue">
            {summary?.topics?.flat().join(', ') ?? '-'}
          </span>
        </p>
      </section>
      <section className="py-2 mb-1 border-b">
        <h5 className="text-sm font-bold">Summary of call</h5>
        {summary?.summary ?? (
          <p className="text-xs">Summary not available yet.</p>
        )}
      </section>
      <section className="py-2 mb-1 border-b">
        <h5 className="text-sm font-bold">Outcome of call</h5>
        {summary?.outcome ? (
          <div className="flex flex-row items-center">
            {ICONS[summary.outcome]}
            {OUTCOME[summary.outcome]}
          </div>
        ) : (
          <p className="text-xs">Outcome not available yet.</p>
        )}
      </section>
      <section className="py-2 mb-1 border-b">
        <h5 className="text-sm font-bold">Call notes</h5>
        <p className="text-xs">Call notes not available yet.</p>
      </section>
      {summary?.missedOpportunity && (
        <div className="p-3 my-4 border rounded-lg w-fit bg-colorRed/5 border-colorRed">
          <div className="flex flex-row items-center">
            <FontAwesomeIcon
              icon={faTriangleExclamation}
              className="text-colorRed mr-2 max-w-[20px]"
              size="2x"
            />
            <p className="font-bold text-colorRed">Missed Opportunity</p>
          </div>
          <p className="text-colorBlack">{summary?.missedOpportunity}</p>
        </div>
      )}
    </div>
  );
}
