import { ReactNode } from 'react';
import { usePermissionContext } from '../../context/PermissionsProvider';
import InvalidPermissions from '../InvalidPermissions/InvalidPermissions';

export default function HiperCoachContainer({
  children,
  sideComponent,
}: {
  children?: ReactNode;
  sideComponent?: ReactNode;
}) {
  const { getPermission } = usePermissionContext();
  const hasViewCoachingPermission = getPermission('se-view_coaching');

  return (
    <>
      {hasViewCoachingPermission ? (
        <div className="flex flex-col w-full gap-y-8 pt-7">
          <div className="flex flex-wrap items-baseline justify-between">
            <h2 className="mb-5 mr-10 font-sans text-3xl font-medium text-colorGray">
              KPIs
            </h2>
            {sideComponent}
          </div>
          {children}
        </div>
      ) : (
        <InvalidPermissions type="page" />
      )}
    </>
  );
}
