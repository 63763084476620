import { usePermissionContext } from '../../context/PermissionsProvider';
import ConversationChecklistForEngagement from '../ConversationChecklist/EngagementAnalytics';
import InvalidPermissions from '../InvalidPermissions/InvalidPermissions';
import FeedbackArea from '../FeedbackArea/FeedbackArea';

interface EngagementAnalyticsSectionProps {
  engagementId: string;
  agent?: AgentData;
}

const EngagementAnalyticsSection = ({
  engagementId,
  agent,
}: EngagementAnalyticsSectionProps) => {
  const { getPermission } = usePermissionContext();
  const hasEngagementConversationChecklistPermissions = getPermission(
    'se-view_convo_checklist_ev'
  );

  return (
    <div className="w-full">
      {hasEngagementConversationChecklistPermissions ? (
        <div className="flex">
          <ConversationChecklistForEngagement
            engagementId={engagementId}
            expansible={false}
          />
          <FeedbackArea agent={agent} />
        </div>
      ) : (
        <InvalidPermissions type={'component'} />
      )}
    </div>
  );
};

export default EngagementAnalyticsSection;
