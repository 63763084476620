import { cn } from '../../utils';

export interface TabFormMenuItemProps {
  title: string;
  isActive: boolean;
  onClick(): void;
}

const TabFormMenuItem = ({
  title,
  isActive,
  onClick,
}: TabFormMenuItemProps) => {
  const isActiveStyle = isActive
    ? 'border-l-colorBlue text-colorGray'
    : 'border-l-white text-colorLightGray';

  return (
    <span
      onClick={onClick}
      className={cn(
        'p-1 pl-2 cursor-pointer border-l-2 font-medium text-ellipsis whitespace-nowrap overflow-hidden',
        isActiveStyle
      )}
    >
      {title}
    </span>
  );
};

export default TabFormMenuItem;
