import { createBrowserRouter, Link } from 'react-router-dom';
import HiperCoach from './pages/HiperCoach';
import HiperInsights from './pages/HiperInsights';
import MyTeam from './pages/MyTeam';
import RootLayout from './RootLayout';
import AgentProfile from './pages/agent-profile';
import ErrorMessage from './components/ErrorMessage';
import MyTeamContainer from './components/MyTeam/MyTeamContainer';
import HiperCoachContainer from './components/HiperCoach/HiperCoachContainer';
import InsightsContainer from './components/HiperInsights/InsightsContainer';
import EngagementDetail from './pages/engagement-detail';
import Settings from './pages/Settings';
import SettingsContainer from './components/Settings/SettingsContainer';
import HiperPlay from './pages/HiperPlay';
import HiperPlayContainer from './components/HiperPlay/HiperPlayContainer';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    handle: {
      crumb: () => (
        <Link to="/" className="text-base font-medium text-colorBlue/80">
          HiPer Insights
        </Link>
      ),
    },
    children: [
      {
        path: '',
        element: <HiperInsights />,
        errorElement: (
          <InsightsContainer>
            <ErrorMessage />
          </InsightsContainer>
        ),
      },
    ],
  },
  {
    path: '/hiper-coach',
    element: <RootLayout />,
    handle: {
      crumb: () => (
        <Link
          to="/hiper-coach"
          className="text-base font-medium text-colorBlue/80"
        >
          HiPer Coach
        </Link>
      ),
    },
    children: [
      {
        path: '',
        element: <HiperCoach />,
        errorElement: (
          <HiperCoachContainer>
            <ErrorMessage />
          </HiperCoachContainer>
        ),
      },
    ],
  },
  {
    path: '/my-team',
    element: <RootLayout />,
    handle: {
      crumb: () => (
        <Link to="/my-team" className="text-base font-medium text-colorBlue/80">
          My Team
        </Link>
      ),
    },
    children: [
      {
        path: '',
        element: <MyTeam />,
        errorElement: (
          <MyTeamContainer>
            <ErrorMessage />
          </MyTeamContainer>
        ),
      },
      {
        path: 'agent/:agentId',
        handle: {
          crumb: (_data: unknown, params: Record<string, string>) => {
            return (
              <Link
                to={`/my-team/agent/${params.agentId}`}
                className="text-base font-medium text-colorBlue/80"
              >
                Agent Profile
              </Link>
            );
          },
        },
        children: [
          {
            path: '',
            element: <AgentProfile />,
            errorElement: <ErrorMessage />,
          },
          {
            path: 'engagements/:engagementId',
            element: <EngagementDetail />,
            errorElement: <ErrorMessage />,
            handle: {
              crumb: (_data: unknown, params: Record<string, string>) => {
                return (
                  <Link
                    to={`/my-team/agent/${params.agentId}/engagements/${params.engagementId}`}
                    className="text-base font-medium text-colorBlue/80"
                  >
                    Engagement
                  </Link>
                );
              },
            },
          },
        ],
      },
    ],
  },
  {
    path: '/settings',
    element: <RootLayout />,
    handle: {
      crumb: () => (
        <Link
          to="/settings"
          className="text-base font-medium text-colorBlue/80"
        >
          Settings
        </Link>
      ),
    },
    children: [
      {
        path: '',
        element: <Settings />,
        errorElement: (
          <SettingsContainer>
            <ErrorMessage />
          </SettingsContainer>
        ),
      },
    ],
  },
  {
    path: '/hiper-play',
    element: <RootLayout />,
    handle: {
      crumb: () => (
        <Link
          to="/hiper-play"
          className="text-base font-medium text-colorBlue/80"
        >
          Hiper Play
        </Link>
      ),
    },
    children: [
      {
        path: '',
        element: <HiperPlay />,
        errorElement: (
          <HiperPlayContainer>
            <ErrorMessage />
          </HiperPlayContainer>
        ),
      },
    ],
  },
]);
