import { api } from './api';
import {
  DEMO_AGENT_EXTERNAL_ID,
  getAgentRecommendationsMock,
  getConversationChecklistForAgentMock,
} from './demo/data';

const SUPEX_BASE_URL = '/supex/';
const contact_mode = 'both';

const Routes = {
  ConversationChecklist: SUPEX_BASE_URL + 'conversationchecklist/',
  ConversationChecklistForSingleDate:
    SUPEX_BASE_URL + 'conversationchecklist/singleday',
  ConversationchecklistForAgent:
    SUPEX_BASE_URL + 'conversationchecklistForAgent/',
  ConversationchecklistForEngagement:
    SUPEX_BASE_URL + 'conversationchecklistForEngagement/',
  LeaderBoardForConversationCheckList:
    SUPEX_BASE_URL + 'leaderBoardForConversationCheckList/',
  GetAgentRecommendations: SUPEX_BASE_URL + 'agentRecommendations/',
};

export async function getConversationChecklist(params?: APISearchParams) {
  const startDate = params?.startDate;
  const endDate = startDate === params?.endDate ? null : params?.endDate;
  const response = await api.get<ConversationChecklist[]>(
    Routes.ConversationChecklist,
    {
      params: {
        start_date: startDate,
        end_date: endDate,
        programs: params?.programs?.join(','),
        centers: params?.center?.join(','),
        departments: params?.department?.join(','),
        managers: params?.directSupervisor?.join(','),
      },
    }
  );
  return response.data;
}

export async function getConversationChecklistForSingleDay(
  params?: APISearchParams
) {
  const startDate = params?.startDate;
  const response = await api.get<ConversationChecklist[]>(
    Routes.ConversationChecklistForSingleDate,
    {
      params: {
        start_date: startDate,
        programs: params?.programs?.join(','),
        centers: params?.center?.join(','),
        departments: params?.department?.join(','),
        managers: params?.directSupervisor?.join(','),
      },
    }
  );

  /**
   * Response example: [{}]
   *
   * This should be fixed in stored procedure and then removed from FE validation.
   */
  if (response.data.length === 1) {
    const [first] = response.data;

    if (!Object.keys(first).length) {
      return [];
    }
  }

  return response.data;
}

export async function getConversationChecklistForAgent(
  agentExternalId: string
) {
  if (agentExternalId === DEMO_AGENT_EXTERNAL_ID) {
    return getConversationChecklistForAgentMock;
  }

  const response = await api.get<ConversationChecklist[]>(
    Routes.ConversationchecklistForAgent,
    {
      params: {
        agent_external_id: agentExternalId,
        contact_mode,
      },
    }
  );

  return response.data;
}

export async function getConversationChecklistForEngagement(
  engagementId: string,
  tenantPublicId?: number
) {
  const response = await api.get<ConversationChecklist[]>(
    Routes.ConversationchecklistForEngagement,
    {
      params: {
        engagement_id: engagementId,
        contact_mode,
      },
    }
  );

  return response.data;
}

export async function getConversationChecklistLeaderboard(
  selectedWeek: string,
  recType: string
) {
  const response = await api.get<ConversationChecklistLeaderBoard[]>(
    Routes.LeaderBoardForConversationCheckList,
    {
      params: {
        selected_week: selectedWeek,
        rec_type: recType,
      },
    }
  );

  return response.data;
}

export async function getAgentRecommendations(
  agentExternalId: string,
  weekBeginDate: string,
  recType: string
) {
  if (agentExternalId === DEMO_AGENT_EXTERNAL_ID) {
    if (recType === 'Motivation') {
      return getAgentRecommendationsMock;
    }

    return [];
  }

  const response = await api.get<GetAgentRecommendations[]>(
    Routes.GetAgentRecommendations,
    {
      params: {
        week_begin_date: weekBeginDate,
        agent_id: agentExternalId,
        rec_type: recType,
      },
    }
  );

  return response.data;
}
