import { memo, useMemo, useState, ReactElement } from 'react';
import TabFormMenuItem, { TabFormMenuItemProps } from './TabFormMenuItem';

interface TabFormContainerProps {
  children:
    | ReactElement<TabFormMenuItemProps>
    | ReactElement<TabFormMenuItemProps>[];
}

const TabFormContainer = ({ children }: TabFormContainerProps) => {
  const [activeTab, setActiveTab] = useState(0);

  const childrenArray = useMemo(
    () => (Array.isArray(children) ? children : [children]),
    [children]
  );

  const activeChildren = useMemo(
    () => childrenArray[activeTab],
    [activeTab, childrenArray]
  );

  const handleActiveTab = (tabNumber: number) => {
    setActiveTab(tabNumber);
  };

  const menuList: string[] = useMemo(() => {
    return childrenArray.map(
      (c: ReactElement<TabFormMenuItemProps>) => c.props.title
    );
  }, [childrenArray]);

  return (
    <div className="grid gap-2 grid-cols-tabForm">
      <div className="flex flex-col gap-2">
        {menuList.map((item, index) => (
          <TabFormMenuItem
            key={item}
            title={item}
            onClick={() => handleActiveTab(index)}
            isActive={index === activeTab}
          />
        ))}
      </div>
      <div>{activeChildren}</div>
    </div>
  );
};

export default memo(TabFormContainer);
