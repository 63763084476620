import {
  endOfMonth,
  lastDayOfWeek,
  startOfMonth,
  startOfWeek,
  startOfYear,
  sub,
  format,
} from 'date-fns';

export function getToday() {
  const today = new Date();
  return [today];
}

export function aWeekFromToday() {
  const today = new Date();
  const aWeekAgo = sub(today, { weeks: 1 });
  return [aWeekAgo, today];
}

export function getThisWeek() {
  const today = new Date();
  const startDate = startOfWeek(today, { weekStartsOn: 0 });
  return [startDate, today];
}

export function getLastWeek() {
  const aWeekAgo = sub(new Date(), { weeks: 1 });
  const startDate = startOfWeek(aWeekAgo, { weekStartsOn: 1 });
  const endDate = lastDayOfWeek(aWeekAgo, { weekStartsOn: 1 });
  return [startDate, endDate];
}

export function getThisMonth() {
  const today = new Date();
  const startDate = startOfMonth(today);
  return [startDate, today];
}

export function getLastMonth() {
  const aMonthAgo = sub(new Date(), { months: 1 });
  const startDate = startOfMonth(aMonthAgo);
  const endDate = endOfMonth(aMonthAgo);
  return [startDate, endDate];
}

export function getThisYear() {
  const today = new Date();
  const startDate = startOfYear(today);
  return [startDate, today];
}

export function formatDateRange(dates: Date[]) {
  const filteredDates = dates.filter(Boolean);
  if (filteredDates.length === 0) {
    return getThisWeek().map((date) => format(date, 'yyyy-MM-dd'));
  }
  if (filteredDates.length === 1) {
    return [
      format(filteredDates[0], 'yyyy-MM-dd'),
      format(filteredDates[0], 'yyyy-MM-dd'),
    ];
  }
  return filteredDates.map((date) => format(date, 'yyyy-MM-dd'));
}
