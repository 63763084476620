import { api } from './api';
import { rowsPerPageOptions } from '../components/Agents/constants';

import {
  getAgentsByLoginMock,
  getEngagementTranscriptMock,
  getSingleEngagementDetailMock,
  isMockEngagement,
  getShuffledEngagementDetailsMock,
  DEMO_AGENT_EXTERNAL_ID,
} from './demo/data';

const contact_mode = 'both';

export async function getCallBreakdownData(params: APISearchParams) {
  const startDate = params?.startDate;
  let endDate = params?.endDate;
  if (endDate === null || endDate === '') {
    endDate = startDate;
  }
  const response = await api.get<CallBreakdownType>(`/supex/callsBreakdown/`, {
    params: {
      start_date: startDate,
      end_date: endDate,
      programs: params?.programs?.join(','),
      centers: params?.center?.join(','),
      departments: params?.department?.join(','),
      managers: params?.directSupervisor?.join(','),
    },
  });
  return response.data;
}

export async function getTopFiveTopics(
  params: APISearchParams
): Promise<TopFiveTopicsReponse> {
  const startDate = params?.startDate;
  let endDate = params?.endDate;
  if (endDate === null || endDate === '') {
    endDate = startDate;
  }
  const response = await api.get<TopFiveTopicsReponse>(
    `/supex/topFiveTopics/`,
    {
      params: {
        start_date: startDate,
        end_date: endDate,
        programs: params?.programs?.join(','),
        centers: params?.center?.join(','),
        departments: params?.department?.join(','),
        managers: params?.directSupervisor?.join(','),
      },
    }
  );
  return response.data;
}

export async function getLeaderboard(
  tenantId?: number,
  params?: APISearchParams
): Promise<LeaderboardResponse> {
  let response;
  if (
    params?.center == null &&
    params?.programs == null &&
    params?.department == null &&
    params?.directSupervisor == null
  ) {
    response = await api.get<LeaderboardResponse>(
      `/supex/leaderboard/${tenantId}?date=${params?.startDate}`
    );
  } else {
    response = await api.get<LeaderboardResponse>(
      `/supex/leaderboard/${tenantId}`,
      {
        params: {
          programs: params?.programs?.join(','),
          centers: params?.center?.join(','),
          departments: params?.department?.join(','),
          managers: params?.directSupervisor?.join(','),
        },
      }
    );
  }
  return response.data;
}
export async function getTotalCalls(
  params?: APISearchParams
): Promise<TotalCallsResponse> {
  const startDate = params?.startDate;
  const endDate = params?.endDate;
  const response = await api.get<TotalCallsResponse>(`/supex/totalcalls`, {
    params: {
      start_date: startDate,
      end_date: endDate,
      programs: params?.programs?.join(','),
      centers: params?.center?.join(','),
      departments: params?.department?.join(','),
      managers: params?.directSupervisor?.join(','),
    },
  });
  return response.data;
}

export async function getTopicBreakdown(
  params?: APISearchParams
): Promise<TopicBreakdownResponse | null> {
  const response = await api.get<NewTreeMapNode[]>(`/supex/topicTreeMap/`, {
    params: {
      start_date: params?.startDate,
      end_date: params?.endDate ?? params?.startDate,
      programs: params?.programs?.join(','),
      centers: params?.center?.join(','),
      departments: params?.department?.join(','),
      managers: params?.directSupervisor?.join(','),
    },
  });
  if (response.data)
    return {
      name: '',
      color: 'white',
      children: response.data.map((res) => ({
        ...res,
        color: '#8060ff',
      })),
    };

  return null;
}

export async function getLoginSummary(params?: APISearchParams) {
  const startDate = params?.startDate;
  const endDate = params?.endDate;

  const response = await api.get<LoginSummaryResponse>(`/supex/loginSummary/`, {
    params: {
      start_date: startDate,
      end_date: endDate,
      programs: params?.programs?.join(','),
      centers: params?.center?.join(','),
      departments: params?.department?.join(','),
      managers: params?.directSupervisor?.join(','),
    },
  });
  return response.data;
}

export async function getOnlineAgentActivity() {
  const apiEndpoint = `/supex/online/agentsactivity/`;

  const response = await api.get<OnlineAgentActivityResponse>(apiEndpoint);
  return response.data;
}

export async function getDashboardFilterOptions() {
  type FetchDashboardFilterOptions = {
    programs: FilterOption[];
    departments: string[];
    managers: string[];
    centers: string[];
  };

  const filters = await api.get<FetchDashboardFilterOptions>(
    `/supex/dashboardFilterByAttributesOptions/`
  );

  const [filtersResponse] = await Promise.all([filters]);

  const filterCriteria: DashboardFilterOptions = {
    center: filtersResponse.data.centers.map((item) => ({
      name: item,
      id: item,
    })),
    department: filtersResponse.data.departments.map((item) => ({
      name: item,
      id: item,
    })),
    directSupervisor:
      filtersResponse.data?.managers?.map((item) => ({
        name: item,
        id: item,
      })) || [],
    programs:
      filtersResponse.data?.programs?.map(({ id, name }) => ({
        name,
        id,
      })) || [],
  };

  return filterCriteria;
}

export async function getFilteredAgents(
  formattedFilters = '&firstName.contains=&sort=firstName,asc',
  pageSize = rowsPerPageOptions[0],
  pageNumber = 0
) {
  if (formattedFilters === '&login.equals=' + DEMO_AGENT_EXTERNAL_ID) {
    return getAgentsByLoginMock;
  }

  const response = await api.get<AgentSearchApiResponse>(
    `/api/platform/search?size=${pageSize}&page=${pageNumber}${formattedFilters}`
  );
  return response.data;
}

export function getAgentByLogin(login: string) {
  return getFilteredAgents('&login.equals=' + login, 1);
}

export async function addAgentToFavorites(
  tenantId: number,
  agentExternalId: string | number
) {
  const { data } = await api.get(
    `/api/platform/${tenantId}/supervisors/addFavorites`,
    { params: { agent_external_id: agentExternalId } }
  );

  return data;
}

export async function removeAgentFromFavorites(
  tenantId: number,
  agentExternalId: string | number
) {
  const { data } = await api.get(
    `/api/platform/${tenantId}/supervisors/removeFromFavorites`,
    { params: { agent_external_id: agentExternalId } }
  );

  return data;
}

export async function getAgentEngagementDetails(
  agentExternalId: string
): Promise<EngagementDetail[]> {
  if (agentExternalId === DEMO_AGENT_EXTERNAL_ID.toUpperCase()) {
    return getShuffledEngagementDetailsMock();
  }

  const response = await api.get<EngagementDetail[]>(
    `/supex/engagementDetails/`,
    {
      params: {
        agent_external_id: agentExternalId,
        contact_mode,
      },
    }
  );
  return response.data;
}

export async function getEngagementTranscript(
  engagementId: string,
  startDate: string
): Promise<EngagementTranscript[]> {
  if (isMockEngagement(engagementId)) {
    return getEngagementTranscriptMock;
  }

  const response = await api.get<
    { sender: string; timestamp: string; text: string }[]
  >(`/supex/engagementTranscript/`, {
    params: {
      engagement_id: engagementId,
      start_date: startDate,
      contact_mode,
    },
  });

  return response.data.map((transcript) => ({
    from: transcript.sender as EngagementTranscript['from'],
    text: transcript.text,
    timestamp: transcript.timestamp,
  }));
}

export async function getSingleEngagementDetail(
  engagementId: string,
  agentExternalId: string
) {
  if (isMockEngagement(engagementId)) {
    return getSingleEngagementDetailMock;
  }

  const response = await api.get<EngagementDetail>(
    `/supex/singleEngagementDetail/`,
    {
      params: {
        engagement_external_id: engagementId,
        agent_external_id: agentExternalId,
        contact_mode,
      },
    }
  );

  return response.data;
}

export async function getProgramList(tenantId: number) {
  const response = await api.get<AgentFetchResponse<AgentProgram>>(
    `/api/platform/programs`,
    {
      params: { tenantPublicId: tenantId },
    }
  );

  return response.data;
}

export async function getSupervisorList(tenantId: number) {
  const response = await api.get<AgentFetchResponse<AgentSupervisor>>(
    `/api/platform/supervisors`,
    {
      params: { tenantPublicId: tenantId },
    }
  );

  return response.data;
}

export async function getEngagementSummary(engagementId: string) {
  const response = await api.get<EngagementSummary>(
    `/supex/getSingleEngagementSummary/`,
    { params: { engagement_id: engagementId, contact_mode } }
  );

  return response.data;
}

export async function getTogglzFeatures() {
  const apiEndpoint = `/supex/togglz/features`;
  const response = await api.get<TogglzFeaturesDTO[]>(apiEndpoint);

  return response.data;
}

export async function getAgentCount(managerName: string) {
  const apiEndpoint = `/supex/agentCount`;
  const response = await api.get<AgentCountDTO[]>(apiEndpoint, {
    params: { managerName },
  });

  return response.data;
}
