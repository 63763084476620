import { Button } from '@xsell/xsell-ui';
import { UseFormGetValues } from 'react-hook-form';
import styles from './styles.module.css';

interface TabButtonsProps {
  onSubmit: UseFormGetValues<any>;
}

const TabButtons = ({ onSubmit }: TabButtonsProps) => {
  const handleSubmit = () => {
    // TODO - Implement -> Submit form values to BE and show alert message
  };

  return (
    <>
      <Button label="Cancel" className={styles.cancel} text />
      <Button label="Save" onClick={handleSubmit} className={styles.confirm} />
    </>
  );
};

export default TabButtons;
