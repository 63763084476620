import { ReactNode } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

export default function ErrorMessage({
  children = 'Something went wrong, please try again later',
}: {
  children?: ReactNode;
}) {
  return (
    <div className="flex flex-col items-center">
      <FontAwesomeIcon icon={faTriangleExclamation} size="2x" color="#888888" />
      <p className="text-sm font-medium text-colorGray">{children}</p>
    </div>
  );
}
