import { format } from 'date-fns';

export const queryBuilder = ({
  firstName = '',
  agentExternalId = '',
  programId = null,
  supervisorId = null,
  sort,
}: AgentsDataTableFilters) => {
  const query = [];

  if (firstName) {
    query.push(`firstName.contains=${firstName}`);
  }

  if (agentExternalId) {
    query.push(`agentExternalId.contains=${agentExternalId}`);
  }

  if (programId) {
    query.push(`programId.equals=${programId}`);
  }

  if (supervisorId) {
    query.push(`directSupervisorId.equals=${supervisorId}`);
  }

  if (sort) {
    const sortOrder = `sort=${sort.field},${sort.order === 1 ? 'asc' : 'desc'}`;

    query.push(sortOrder);
  }

  if (!query.length) return '';

  return '&' + query.join('&');
};

export const splitAgentList = (data: AgentData[] = []) => {
  const watchList: AgentData[] = [];
  const regularList: AgentData[] = [];

  data.forEach((agent) => {
    agent.watchList ? watchList.push(agent) : regularList.push(agent);
  });

  return {
    watchList,
    regularList,
  };
};

export const engagementLinkBuilder = (
  timestamp: string,
  engagementId: string
) => {
  return `engagements/${engagementId}?startDate=${format(
    new Date(timestamp),
    'yyyy-MM-dd'
  )}`;
};
