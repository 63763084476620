import { memo, ReactNode } from 'react';
import { usePermissionContext } from '../../context/PermissionsProvider';
import InvalidPermissions from '../InvalidPermissions/InvalidPermissions';

const SettingsContainer = ({ children }: { children?: ReactNode }) => {
  const { getPermission } = usePermissionContext();
  const hasViewSettingsPermission = getPermission('se-view_settings');

  return (
    <>
      {hasViewSettingsPermission ? (
        <div>
          <div className="flex flex-col items-baseline justify-between py-7 md:flex-row">
            <h2 className="font-sans text-3xl font-medium text-colorGray">
              Settings
            </h2>
          </div>
          <div className="bg-white rounded-2xl p-7">
            <h5 className="text-sm text-colorGray">
              Manage your account settings and preferences.
            </h5>
            <div className="w-full mt-4 mb-2 border-b" />
            {children}
          </div>
        </div>
      ) : (
        <InvalidPermissions type={'page'} />
      )}
    </>
  );
};

export default memo(SettingsContainer);
