import { TopicListWidget, TopicListProps } from '@xsell/xsell-ui';
import { useGetTopFiveTopics } from '../../hooks/queries';

const infoTextMessage =
  'This widget displays the top 5 topics in frequency of occurrence, the bar represents how frequent the topic was within the total of engagements. This Widget’s footer always displays an Insight relevant to the most frequent topic.';

export default function TopTopicsWidget({
  queryParams,
}: {
  queryParams: APISearchParams;
}) {
  const { data, isLoading, isError } = useGetTopFiveTopics(queryParams);

  const topicData: TopicListProps = {
    title: 'Top 5 Topics',
    topics: [],
    footerMessage: '',
  };

  if (data?.topFiveTopics?.length) {
    topicData.topics = [...data.topFiveTopics].filter(
      ({ topicName }) => topicName?.length
    );
    const totalCount =
      topicData.topics.reduce(
        (result: number, topic: { topicCount: number } | undefined) =>
          result + (topic?.topicCount ?? 0),
        0
      ) ?? 0;

    const percentage = Math.round(
      ((topicData.topics[0]?.topicCount || 0) / totalCount) * 100
    );

    topicData.footerMessage =
      !!topicData.topics[0] &&
      `${topicData.topics[0].topicName} appears in ${percentage}% of all conversations`;
  }

  const optional = {
    barColors: ['#4A1DFB', '#6F4AFF', '#8D71FF', '#BEADFF', '#DDD4FF'],
  };

  return (
    <TopicListWidget
      data={topicData}
      isLoading={isLoading}
      error={isError}
      optional={optional}
      className="text-colorBlack"
      infoText={infoTextMessage}
    />
  );
}
