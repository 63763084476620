import { memo, useRef, useState } from 'react';
import { CirclePacking } from '@xsell/xsell-ui';
import TopicBreakDownDetails, {
  TopicData,
} from '../../TopicBreakDownDetails/TopicBreakDownDetails';

interface CircularTreemapProps {
  data: TopicBreakdownResponse;
}

const CircularTreemapPresentation = ({ data }: CircularTreemapProps) => {
  const [topicData, setTopicData] = useState<TopicData>({
    topicName: '',
    percentage: null,
    conversationAmount: null,
  });

  return (
    <div className="h-96 flex">
      <section className="w-8/12 h-full">
        <CirclePacking
          data={data}
          labelTextColor={{
            from: 'color',
            modifiers: [['brighter', 20]],
          }}
          motionConfig="slow"
          labelsSkipRadius={50}
          colors={{ datum: 'data.color' }}
          inheritColorFromParent={false}
          padding={10}
          theme={{
            labels: {
              text: {
                fontSize: 15,
                stroke: 'black',
                fontFamily: 'Roboto, sans-serif',
                strokeOpacity: 0.3,
                color: 'red',
                backgroundColor: 'blue',
                strokeWidth: 0.7,
                strokeLinecap: 'round',
              },
            },
          }}
          label={(n) => n.data.name}
          enableLabels
          onClick={(node) => {
            setTopicData({
              topicName: node.data.name,
              percentage: Math.round(node.percentage),
              conversationAmount: node.value,
            });
          }}
        />
      </section>
      <span className="border-l-2 border-dotted p-2" />
      {topicData.topicName && (
        <TopicBreakDownDetails
          topicName={topicData.topicName}
          percentage={topicData.percentage}
          conversationAmount={topicData.conversationAmount}
        />
      )}
    </div>
  );
};

export default memo(CircularTreemapPresentation);
