import { memo, ReactNode } from 'react';
import { usePermissionContext } from '../../context/PermissionsProvider';
import InvalidPermissions from '../InvalidPermissions/InvalidPermissions';

const MyTeamContainer = ({ children }: { children?: ReactNode }) => {
  const { getPermission } = usePermissionContext();
  const hasViewMyTeamPermission = getPermission('se-view_team');

  return (
    <>
      {hasViewMyTeamPermission ? (
        <div>
          <div className="flex flex-col items-baseline justify-between py-7 md:flex-row">
            <h2 className="font-sans text-3xl font-medium text-colorGray">
              My Team
            </h2>
          </div>
          {children}
        </div>
      ) : (
        <InvalidPermissions type="page" />
      )}{' '}
    </>
  );
};

export default memo(MyTeamContainer);
