export const pagesPermissions = [
  'se-view_settings',
  'se-view_team',
  'se-view_insights',
  'se-view_coaching',
  'se-view_play',
];

export const PagesPermissions = {
  ViewSettings: pagesPermissions[0],
  ViewTeam: pagesPermissions[1],
  ViewInsights: pagesPermissions[2],
  ViewCoaching: pagesPermissions[3],
  ViewPlay: pagesPermissions[4],
} as const;
