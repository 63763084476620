import {
  HiperPlayProvider,
  HiperPlayEngagementWidget,
  HiperPlayWidget,
  HiperPlayDropdownItemProps,
} from '@xsell/xsell-ui';
import { memo, useEffect, useRef, useState } from 'react';
import HiperPlayContainer from '../components/HiperPlay/HiperPlayContainer';
import { useGetDashboardFilterOptions } from '../hooks/queries';

interface EngagementSettings {
  application: string;
  program: string;
}

const applications: HiperPlayDropdownItemProps[] = [
  {
    name: 'Voice',
    value: 'voice',
  },
  {
    name: 'Chat',
    value: 'chat',
  },
];

const getContainer = () =>
  document.querySelector('iframe') as HTMLIFrameElement;

const handleEvent = (
  programId: string,
  engagementId: string,
  event:
    | 'engagement_start'
    | 'engagement_end'
    | 'agent_message'
    | 'visitor_message',
  content = 'content'
) => {
  const container = getContainer();

  if (container?.contentWindow) {
    container.contentWindow.postMessage(
      `{"engagementId": "${engagementId}","type": "${event}","program": "${programId}","content": "${content}"}`,
      {
        targetOrigin: '*',
      }
    );
  }
};

const isKnownApp = (origin: string) =>
  origin.includes('voice') || origin.includes('chat');

const makeEngagementId = () => 'SUPEX' + Math.round(Math.random() * 10000);

const handleApplication = (application: string) =>
  `${process.env.REACT_APP_API_AGEX_SERVER_URL}?app=${application}&provider_name=xsell`;

const HiperPlay = () => {
  const engagementIdRef = useRef('');
  const [isAppReady, setIsAppReady] = useState(false);

  const [app, setApp] = useState<EngagementSettings | null>(null);
  const { data } = useGetDashboardFilterOptions();

  let programList: HiperPlayDropdownItemProps[] = [];

  if (data?.programs) {
    programList = data.programs.map(({ id, name }) => ({
      name,
      value: id,
    }));
  }

  useEffect(() => {
    const isReadyListener = (ev: MessageEvent) => {
      console.log('origin', ev.origin);

      if (isKnownApp(ev.origin) && !isAppReady) {
        console.log('isKnownApp');
        try {
          const { data } = ev;
          const parsedData: { status: string } = JSON.parse(data);

          if (parsedData.status === 'websocketOpen') {
            console.log('websocketOpen');
            setIsAppReady(true);
          }
        } catch (err) {
          console.error('Error parsing data', err);
        }
      }
    };

    window.addEventListener('message', isReadyListener);

    return () => {
      window.removeEventListener('message', isReadyListener);
    };
  }, [isAppReady]);

  useEffect(() => {
    if (isAppReady && app?.program) {
      handleEvent(app.program, engagementIdRef.current, 'engagement_start');
    }
  }, [app?.program, isAppReady]);

  return (
    <HiperPlayProvider>
      <HiperPlayContainer>
        <section className="flex gap-4 h-3/4">
          <HiperPlayWidget
            title="Hello Supervisor"
            dropdownFields={{ applications, programs: programList }}
            onStart={({ application, program }, cb) => {
              setApp({
                application: handleApplication(application),
                program,
              });

              engagementIdRef.current = makeEngagementId();

              cb();
            }}
            onEnd={(cb) => {
              if (app?.program) {
                handleEvent(
                  app.program,
                  engagementIdRef.current,
                  'engagement_end'
                );
              }

              setApp(null);
              setIsAppReady(false);
              cb();
            }}
            onAgentMessage={({ content }, cb) => {
              console.log('agent', {
                isAppReady,
                program: app?.program,
                engagementIdRef,
                content,
              });

              if (isAppReady && app?.program) {
                handleEvent(
                  app.program,
                  engagementIdRef.current,
                  'agent_message',
                  content
                );
                cb();
              }
            }}
            onVisitorMessage={({ content }, cb) => {
              console.log('visitor', {
                isAppReady,
                program: app?.program,
                engagementIdRef,
                content,
              });

              if (isAppReady && app?.program) {
                handleEvent(
                  app.program,
                  engagementIdRef.current,
                  'visitor_message',
                  content
                );
                cb();
              }
            }}
          />
          <HiperPlayEngagementWidget>
            {app && (
              <iframe
                title="The Agent Experience App"
                className="h-full border-4 border-yellow-400"
                src={app.application}
              />
            )}
          </HiperPlayEngagementWidget>
        </section>
      </HiperPlayContainer>
    </HiperPlayProvider>
  );
};

export default memo(HiperPlay);
