import withWidgetCard from '../withWidgetCard';
import LoginSummaryBase from './LoginSummaryBase';

const title = 'Login Summary';
const infoText =
  'This widget displays the percentage of calls that occurred while agents were logged in versus those that occurred while the agent was logged out. When hovering over the doughnut slices, the corresponding number of calls for the selected percentage will be displayed.';

export default withWidgetCard<BaseQueryProps>(
  LoginSummaryBase,
  title,
  infoText
);
