import { Outlet } from 'react-router-dom';
import ContentLayout from './components/ContentLayout';
import ContentLayoutHeader from './components/ContentLayoutHeader';
import Sidenav from './components/SideNavigation/sidenav';
import AgentListProvider from './context/AgentProvider';
import AlertMessageProvider from './context/MessageProvider';
import PermissionsProvider from './context/PermissionsProvider';
import { useAuth } from './hooks/useAuth';

export default function RootLayout() {
  useAuth();

  return (
    <PermissionsProvider>
      <Sidenav />
      <AlertMessageProvider>
        <AgentListProvider>
          <ContentLayout>
            <ContentLayoutHeader />
            {/* Outlet is where the Child components will be rendered */}
            <Outlet />
          </ContentLayout>
        </AgentListProvider>
      </AlertMessageProvider>
    </PermissionsProvider>
  );
}
