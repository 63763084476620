/* eslint-disable no-console */
import axios from 'axios';

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_SERVER_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  xsrfCookieName: 'XSRF-TOKEN',
  xsrfHeaderName: 'X-XSRF-TOKEN',
  withCredentials: true,
});

const onRequestError = (error: any) => {
  console.error(`[request error] [${JSON.stringify(error)}]`);
  if (error && error.response.status === 401) {
    setTimeout(() => {
      localStorage.clear();
    }, 3000);
  }
};

const onResponseError = async (error: any) => {
  console.error(`[response error] [${JSON.stringify(error)}]`);
  if (error?.response && error.response.status === 401) {
    setTimeout(() => {
      localStorage.clear();
    }, 3000);
  }
};

api.interceptors.request.use((request) => request, onRequestError);
api.interceptors.response.use((response) => response, onResponseError);

export const BACKEND_ROUTES = {
  logout: `${process.env.REACT_APP_API_SERVER_URL ?? ''}/api/oauth/sign_out`,
};
