import { ReactNode } from 'react';
import { cn } from '../../utils';

export default function MyTeamCounter({
  count,
  title,
  icon,
  className,
}: {
  count: string;
  title: string;
  icon?: ReactNode;
  className?: string;
}) {
  return (
    <div
      className={cn(
        'flex flex-1 flex-col gap-y-2 px-6 py-7 drop-shadow-md bg-white rounded',
        className
      )}
    >
      <div className="flex flex-row items-end gap-x-1">
        <p className="text-6xl font-medium text-colorBlue">{count}</p>
        <div className="-mt-10 -ml-1">{icon}</div>
      </div>
      <p className="text-colorGray text-xl font-semibold font-sans">{title}</p>
      <p className="text-colorGray font-normal font-sans">View</p>
    </div>
  );
}
