import { forwardRef } from 'react';
import { cn } from '../../utils';

const buttonVariants = {
  variant: {
    default: 'bg-slate-900 text-white hover:bg-slate-700 ',
    destructive: 'bg-red-500 text-white hover:bg-red-600 ',
    outline: 'bg-transparent border border-slate-200 hover:bg-slate-100 ',
    subtle: 'bg-slate-100 text-slate-900 hover:bg-slate-200 ',
    ghost:
      'bg-transparent hover:bg-slate-100 data-[state=open]:bg-transparent ',
    link: 'bg-transparent underline-offset-4 hover:underline text-slate-900 hover:bg-transparent ',
  },
  size: {
    default: 'h-10 py-2 px-4',
    sm: 'h-9 px-2 rounded-md',
    lg: 'h-11 px-8 rounded-md',
  },
};

type ButtonVariants = keyof typeof buttonVariants.variant;
type ButtonSizes = keyof typeof buttonVariants.size;

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  variant?: ButtonVariants;
  size?: ButtonSizes;
};

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant = 'default', size = 'default', ...props }, ref) => {
    return (
      <button
        type="button"
        className={cn(
          buttonVariants.variant[variant],
          buttonVariants.size[size],
          className
        )}
        ref={ref}
        {...props}
      />
    );
  }
);
Button.displayName = 'Button';

export default Button;
