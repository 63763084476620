import engagementDetailsMock from './data/engagementDetailsMock.json';
import engagementTranscriptMock from './data/engagementTranscriptMock.json';
import agentRecommendationsMock from './data/agentRecommendationsMock.json';
import conversationChecklistMock from './data/conversationChecklistMock.json';

export const getAgentsByLoginMock: AgentSearchApiResponse = {
  lastPage: 0,
  totalCount: 1,
  content: [
    {
      login: 'ai3979',
      agentExternalId: 'ai3979',
      fullName: 'Aerol Henry Ignacio',
      firstName: 'Aerol Henry',
      lastName: 'Ignacio',
      program: {
        id: 1,
        name: 'Medicare Enrollments',
        description: 'SAMPLE PROGRAM 1 DESCRIPTION',
        externalProgramId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
        tenantPublicId: 10001,
      },
      directSupervisor: {
        id: '3902',
        fullName: 'Colleen Ladas',
      },
      department: {
        name: 'Medicare',
        externalDepartmentId: '11b57c7e-5270-40f4-a4e2-78f4a5d0e66a',
      },
      center: {
        id: '1',
        name: 'Chicago',
      },
      startDate: '2023-06-05T00:00:00Z',
      watchList: false,
      id: 5272,
    },
  ],
};

export const getSingleEngagementDetailMock: EngagementDetail = {
  engagement_id: '7770303234376d6f696732333039323032333134303361',
  program_uid: 'Medicare Enrollments',
  engagement_start_time: '2023-10-18 10:50:30.044',
  topic_name: 'Inbound',
  department: '',
  call_duration_seconds: 1722,
};

export const getEngagementDetailsMock: EngagementDetail[] =
  engagementDetailsMock;

export const getEngagementTranscriptMock =
  engagementTranscriptMock as EngagementTranscript[];

export const isMockEngagement = (engagementId: string) =>
  getEngagementDetailsMock.find(
    ({ engagement_id }) => engagementId === engagement_id
  );

export const getShuffledEngagementDetailsMock = () => [
  getEngagementDetailsMock[0],
  ...getEngagementDetailsMock
    .slice(1, getEngagementDetailsMock.length)
    .sort(() => Math.random() - 0.5),
];

export const DEMO_AGENT_EXTERNAL_ID =
  getAgentsByLoginMock.content[0].agentExternalId;

export const getConversationChecklistForAgentMock: ConversationChecklist[] =
  conversationChecklistMock;

export const getAgentRecommendationsMock: GetAgentRecommendations[] =
  agentRecommendationsMock;
