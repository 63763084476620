import { Calendar } from '@xsell/xsell-ui';
import { useState } from 'react';
import {
  getLastMonth,
  getLastWeek,
  getThisMonth,
  getThisWeek,
  getThisYear,
  getToday,
} from '../../utils/date';
import { cn } from '../../utils';

type SidePanelButtonProps = {
  children: React.ReactNode;
  selected?: boolean;
} & React.HTMLAttributes<HTMLButtonElement>;

function SidePanelButton({
  children,
  selected,
  ...rest
}: SidePanelButtonProps) {
  return (
    <button
      type={'button'}
      className={cn(
        'px-3 py-1 text-slate-600 text-sm rounded',
        selected && 'bg-sky-100 text-sky-600 font-medium'
      )}
      {...rest}
    >
      {children}
    </button>
  );
}

export default function DateRangePicker({
  onApply,
  selectedRange,
  setSelectedRange,
}: DateRangeProps) {
  const [dates, setDates] = useState<Date[] | null>(null);

  return (
    <div className={'bg-white rounded flex divide-x gap-x-2'}>
      <div className={'p-6 flex flex-col justify-between'}>
        <div className="flex flex-col items-start gap-y-1">
          <SidePanelButton
            selected={selectedRange === 'today'}
            onClick={() => {
              setDates(getToday());
              setSelectedRange('today');
            }}
          >
            Today
          </SidePanelButton>
          <SidePanelButton
            selected={selectedRange === 'thisWeek'}
            onClick={() => {
              setDates(getThisWeek());
              setSelectedRange('thisWeek');
            }}
          >
            This Week
          </SidePanelButton>
          <SidePanelButton
            selected={selectedRange === 'lastWeek'}
            onClick={() => {
              setDates(getLastWeek());
              setSelectedRange('lastWeek');
            }}
          >
            Last Week
          </SidePanelButton>
          <SidePanelButton
            selected={selectedRange === 'thisMonth'}
            onClick={() => {
              setDates(getThisMonth());
              setSelectedRange('thisMonth');
            }}
          >
            This Month
          </SidePanelButton>
          <SidePanelButton
            selected={selectedRange === 'lastMonth'}
            onClick={() => {
              setDates(getLastMonth());
              setSelectedRange('lastMonth');
            }}
          >
            Last Month
          </SidePanelButton>
          <SidePanelButton
            selected={selectedRange === 'thisYear'}
            onClick={() => {
              setDates(getThisYear());
              setSelectedRange('thisYear');
            }}
          >
            Year To Date
          </SidePanelButton>
          <div className="mt-4">
            <button
              type={'button'}
              className="px-3 py-1 text-sm font-light bg-transparent rounded text-sky-400 hover:bg-slate-100"
              onClick={() => {
                setSelectedRange(null);
                setDates([]);
              }}
            >
              Clear
            </button>
          </div>
        </div>
      </div>
      <div className="p-6 py-3">
        <Calendar
          panelClassName="dateRangePicker"
          value={dates}
          onChange={(e) => {
            const dates = Array.isArray(e.value) ? e.value : [e.value];
            setDates(dates as Date[]);
            setSelectedRange(null);
          }}
          numberOfMonths={1}
          selectionMode="range"
          readOnlyInput
          showOtherMonths={false}
          inline
        />
        <div className="flex justify-end mt-4">
          <button
            type="button"
            disabled={!dates?.length}
            className="px-3 py-1 text-sm font-light text-white bg-blue-500 rounded hover:bg-blue-600 disabled:opacity-50 disabled:pointer-events-none"
            onClick={() => {
              if (dates) {
                onApply({
                  dates,
                  dateRange: selectedRange,
                });
              }
            }}
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  );
}
