import { Messages as PrimeMessages } from 'primereact/messages';
import { memo, useEffect, useRef } from 'react';

const AlertMessage = ({
  severity,
  detail,
  closable = false,
  handleClear,
}: withClearableMessage<MessageProps>) => {
  const msgs = useRef<PrimeMessages | null>(null);

  useEffect(() => {
    if (detail?.length) {
      msgs.current?.show([{ severity: severity, detail, closable }]);
    }

    return () => {
      handleClear();
    };
  }, [closable, detail, handleClear, severity]);

  return <PrimeMessages ref={msgs} />;
};

export default memo(AlertMessage);
