import TotalEngagements from '../../TotalEngagements';
import withToggleTab from '../../CollapsibleTab/withToggleTab';
import { usePermissionContext } from '../../../context/PermissionsProvider';

const EngagementsInsights = ({ apiSearchParams }: BaseQueryProps) => {
  const { getPermission } = usePermissionContext();
  const hasTotalEngagementsPermission = getPermission(
    'se-view_total_engagements'
  );

  return (
    <section className="flex gap-4 child:min-w-[25rem]">
      {hasTotalEngagementsPermission && (
        <TotalEngagements apiSearchParams={apiSearchParams} />
      )}
    </section>
  );
};

export default withToggleTab<BaseQueryProps>(EngagementsInsights);
