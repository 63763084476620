import { memo, ReactNode } from 'react';
import { usePermissionContext } from '../../context/PermissionsProvider';
import InvalidPermissions from '../InvalidPermissions/InvalidPermissions';

const HiperPlayContainer = ({ children }: { children?: ReactNode }) => {
  const { getPermission } = usePermissionContext();
  const hasViewHiperPlayPermission = getPermission('se-view_play');

  return (
    <>
      {hasViewHiperPlayPermission ? (
        <>
          <div className="flex flex-col items-baseline justify-between py-7 md:flex-row">
            <h2 className="font-sans text-3xl font-medium text-colorGray">
              Hiper Play
            </h2>
          </div>
          {children}
        </>
      ) : (
        <InvalidPermissions type="page" />
      )}
    </>
  );
};

export default memo(HiperPlayContainer);
