import { useLocation } from 'react-router-dom';
import Breadcrumbs from './Breadcrumbs';
import { Notifications } from '@xsell/xsell-ui';

const ContentLayoutHeader = () => {
  const { pathname } = useLocation();

  return (
    <div className="flex justify-between">
      <Breadcrumbs />
      {pathname !== '/hiper-coach' && <Notifications />}
    </div>
  );
};

export default ContentLayoutHeader;
