import { useGetConversationChecklist } from '../../../hooks/queries/conversationChecklist';
import Presentation from './HiperInsightsConversationChecklistPresentation';
import GenericLoader from '../../GenericLoader';
import withWidgetCard from '../../withWidgetCard';
import { formatHeatMapData, infoText, title } from '../utils/utils';

const HiperInsightsConversationChecklist = ({
  apiSearchParams,
  ...props
}: Required<BaseQueryProps> & ExpandProps) => {
  const { data, isLoading, isError } =
    useGetConversationChecklist(apiSearchParams);

  if (isError || isLoading || !data?.length) {
    return (
      <GenericLoader
        isLoading={isLoading}
        isError={isError}
        noData={!data?.length}
      />
    );
  }

  const heatMapData = formatHeatMapData(data);

  return (
    <Presentation
      {...(props as ExpandProps)}
      data={heatMapData}
      isSingleData={false}
      singleDateData={data}
    />
  );
};

const ConversationChecklistForDateRange = withWidgetCard<BaseQueryProps>(
  HiperInsightsConversationChecklist,
  title,
  infoText
);

export { ConversationChecklistForDateRange };
