import { CoachingPlanForm, CoachingPlan } from '@xsell/xsell-ui';
import withToggleTab from '../CollapsibleTab/withToggleTab';
import { useAlertMessage } from '../../context/MessageProvider';
import './styles.css';
import { usePermissionContext } from '../../context/PermissionsProvider';
import InvalidPermissions from '../InvalidPermissions/InvalidPermissions';
import { useGetConversationChecklistForAgent } from '../../hooks/queries/conversationChecklist';
import GenericLoader from '../GenericLoader';

interface CoachingPlansProps {
  agentData: AgentData;
}

const CoachingPlans = ({ agentData }: CoachingPlansProps) => {
  const { setMessageData } = useAlertMessage();
  const { getPermission } = usePermissionContext();
  const hasCreateCoachingPlanPermission = getPermission(
    'se-create_coaching_plan'
  );

  const { program, fullName, agentExternalId } = agentData;

  const { data, isError, isLoading } =
    useGetConversationChecklistForAgent(agentExternalId);

  if (isError || isLoading) {
    return (
      <GenericLoader
        isLoading={isLoading}
        isError={isError}
        noData={!data?.length}
      />
    );
  }

  const checklistData = data && data?.map((info) => info.id);

  return (
    <>
      {hasCreateCoachingPlanPermission ? (
        <div className="flex flex-col gap-2">
          <div className="flex flex-row gap-4">
            <CoachingPlan
              title="motivation"
              isStarted
              remainingDays={3}
              percentageDone={75}
              recommendationsUsed={4}
            />
            <CoachingPlan title="rapport" />
          </div>
          <div className="self-end">
            <CoachingPlanForm
              onCreatePlan={() =>
                setMessageData('Coaching Plan Created', 'success')
              }
              programName={program?.name}
              agentName={fullName}
              options={checklistData}
            />
          </div>
        </div>
      ) : (
        <InvalidPermissions type="component" />
      )}
    </>
  );
};

export default withToggleTab<CoachingPlansProps>(CoachingPlans);
