import { ReactNode } from 'react';
import { usePermissionContext } from '../../context/PermissionsProvider';
import InvalidPermissions from '../InvalidPermissions/InvalidPermissions';

export default function InsightsContainer({
  children,
  input,
}: {
  children?: ReactNode;
  input?: ReactNode;
}) {
  const { getPermission } = usePermissionContext();
  const hasHiperInsightsPermission = getPermission('se-view_insights');

  return (
    <>
      {hasHiperInsightsPermission ? (
        <div className="flex flex-col w-full gap-y-8 pt-7">
          <div className="flex flex-wrap items-baseline justify-between">
            <h2 className="mb-5 mr-10 font-sans text-3xl font-medium text-colorGray">
              HiPer Insights
            </h2>
            {input}
          </div>
          {children}
        </div>
      ) : (
        <InvalidPermissions type="page" />
      )}
    </>
  );
}
