import { memo, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useAgentContext } from '../../context/AgentProvider';
import Star from '../Star/Star';
import Avatar from '../ui/Avatar';

const AgentInfoCard = ({ agent }: { agent: AgentData }) => {
  const { login, firstName, lastName, watchList, agentExternalId } = agent;
  const { toggleAgentWatchList } = useAgentContext();

  const handleAgentWatchList = useCallback(
    (isWatchListed: boolean, alertMessage: AlertMessageParams) => {
      toggleAgentWatchList(login, isWatchListed, alertMessage);
    },
    [login, toggleAgentWatchList]
  );

  const LinkButton = useMemo(
    () => (
      <Link to={`/my-team/agent/${login}`}>
        <span className="py-3 text-sm font-medium text-blue-500 bg-blue-100 rounded-lg hover:bg-blue-200 px-9">
          View Profile
        </span>
      </Link>
    ),
    [login]
  );

  return (
    <div className="p-6 min-w-[240px] max-w-xs drop-shadow-lg bg-white rounded flex flex-col items-center gap-y-4">
      <Avatar
        key={login}
        agentId={login}
        firstName={firstName}
        lastName={lastName}
      />
      <div className="flex flex-col items-center">
        <div className="flex justify-center gap-x-2">
          <Star
            isInWatchList={watchList}
            setAgentWatchList={handleAgentWatchList}
          />
          <h3 className="text-sm font-medium text-slate-800 max-w-[160px] overflow-hidden whitespace-nowrap text-ellipsis">
            {`${firstName} ${lastName}`}
          </h3>
        </div>
        <p className="text-xs font-light text-colorLightGray max-w-[180px] overflow-hidden whitespace-nowrap text-ellipsis">
          {agentExternalId}
        </p>
      </div>
      {LinkButton}
    </div>
  );
};

export default memo(AgentInfoCard);
