import AgentPreferences from '../components/Settings/AgentPreferences';
import SettingsContainer from '../components/Settings/SettingsContainer';
import TabForm from '../components/TabForm';

const Settings = () => {
  return (
    <SettingsContainer>
      <TabForm.Container>
        <TabForm.Content title="My preferences" Content={AgentPreferences} />
      </TabForm.Container>
    </SettingsContainer>
  );
};

export default Settings;
