import { ChangeEvent, memo, useCallback } from 'react';
import { InputText } from '@xsell/xsell-ui';

interface WatchListSearchProps {
  inputActions: [string, (value: string) => void];
}

const WatchListSearch = ({ inputActions }: WatchListSearchProps) => {
  const [value, setValue] = inputActions;

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value);
    },
    [setValue]
  );

  return (
    <div className="flex gap-1">
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          value={value}
          onChange={handleChange}
          placeholder="Search by name"
        />
      </span>
    </div>
  );
};

export default memo(WatchListSearch);
