import { Paginator, PaginatorPageChangeEvent } from '@xsell/xsell-ui';
import { debounce } from 'lodash';
import { memo, useCallback, useRef, useState } from 'react';
import { useAgentContext } from '../../context/AgentProvider';
import { splitAgentList } from '../../utils/agent';
import GenericLoader from '../GenericLoader';
import SelectMyTeamViewMode from '../SelectMyTeamViewMode';
import WatchListSearch from '../WatchListSearch';
import AgentInfoCard from './AgentInfoCard';
import {
  defaultPagination,
  rowsPerPageOptions,
  FILTER_PREFIX,
  FILTER_SUFFIX,
} from './constants';

type AgentListTitle = 'Watch list' | 'All agents';
const Loader = <GenericLoader isLoading isError={false} noData={false} />;

const handleAgentList = (title: AgentListTitle, list: AgentData[]) => (
  <>
    <h2 className="font-medium text-md text-slate-800">{title}</h2>
    <div className="flex flex-wrap gap-6">
      {list.map((agent) => (
        <AgentInfoCard agent={agent} key={agent.login} />
      ))}
    </div>
  </>
);

const AgentsListSection = () => {
  const latestFilterRef = useRef(FILTER_PREFIX);
  const paginatorConfig = useRef<PaginatorPageChangeEvent>(defaultPagination);
  const { agentList, handleTablePaginatorCallback } = useAgentContext();
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce(
      (
        query: string,
        callback: CallBackFn,
        { rows, page = 0 }: Partial<PaginatorPageChangeEvent>
      ) =>
        handleTablePaginatorCallback(
          {
            rows,
            page,
          },
          query,
          callback
        ),
      500
    ),
    []
  );

  const handleSetInputValue = (inputValue: string) => {
    setInputValue(inputValue);
    latestFilterRef.current = FILTER_PREFIX + inputValue + FILTER_SUFFIX;

    debouncedSearch(latestFilterRef.current, setIsLoading, {
      rows: paginatorConfig.current.rows,
      page: 0,
    });
  };

  const { watchList, regularList } = splitAgentList(agentList?.content);

  const handlePaginator = useCallback(
    (event: PaginatorPageChangeEvent) => {
      paginatorConfig.current = event;

      handleTablePaginatorCallback(
        event,
        latestFilterRef.current,
        setIsLoading
      );
    },
    [handleTablePaginatorCallback]
  );

  return (
    <div className="flex flex-col w-full gap-6">
      <div className="flex items-center self-end gap-2">
        <WatchListSearch inputActions={[inputValue, handleSetInputValue]} />
        <SelectMyTeamViewMode />
      </div>
      {isLoading ? (
        Loader
      ) : (
        <>
          {Boolean(watchList.length) &&
            handleAgentList('Watch list', watchList)}
          {Boolean(regularList.length) &&
            handleAgentList('All agents', regularList)}
        </>
      )}
      <Paginator
        rowsPerPageOptions={rowsPerPageOptions}
        paginatorCallback={handlePaginator}
        totalRecords={agentList?.totalCount ?? 0}
        template="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        currentPageReportTemplate="{first} to {last} of {totalRecords}"
      />
    </div>
  );
};

AgentsListSection.displayName = 'AgentsListSection';

export default memo(AgentsListSection);
