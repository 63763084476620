import { Fragment } from 'react';
import { useGetConversationChecklistLeaderboard } from '../../../hooks/queries/conversationChecklist';
import GenericLoader from '../../GenericLoader';
import { coachingSpotlightColors, leaderColors } from '../utils/utils';
import withDialog from '../withDialog';

import { ComputedCell, HeatMapDatum } from '@nivo/heatmap';

const getAgentList = (
  list: ConversationChecklistLeaderBoard[],
  colors: Record<colorsIndexes, string>
) =>
  list?.length &&
  list.map(({ agent_id, pct_use }, index) => (
    <Fragment key={agent_id}>
      <span
        style={{
          borderRadius: '6px',
          backgroundColor: colors[String(index) as colorsIndexes],
        }}
      ></span>
      <span className="font-medium">{agent_id}</span>
      <span className="font-bold justify-self-end">
        {(pct_use * 100).toFixed(2)}%
      </span>
    </Fragment>
  ));

const ConversationChecklistLeaderboard = ({
  x: date,
  serieId: recType,
}: ComputedCell<HeatMapDatum>) => {
  const {
    data = [],
    isLoading,
    isError,
  } = useGetConversationChecklistLeaderboard(new Date(date), recType);

  if (isLoading || isError || !data.length) {
    return (
      <GenericLoader
        isLoading={isLoading}
        isError={isError}
        noData={!data.length}
      />
    );
  }

  const leaderList = data.slice(0, data.length / 2);
  const coachingSpotlightList = data.slice(data.length / 2, data.length);

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-2">
        <h4 className="text-lg font-medium text-colorGray">Leaderboard</h4>
        <div className="grid gap-1 grid-cols-leaderboard text-colorGray">
          {getAgentList(leaderList, leaderColors)}
        </div>
        <h4 className="text-lg font-medium text-colorGray">
          Coaching Spotlight
        </h4>
        <div className="grid gap-1 grid-cols-leaderboard text-colorGray">
          {getAgentList(coachingSpotlightList, coachingSpotlightColors)}
        </div>
      </div>
    </div>
  );
};

export default withDialog(ConversationChecklistLeaderboard);
