import { useAtom } from 'jotai';
import { navOpened } from '../../utils/jotai';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';

export default function SideNavCollapseButton() {
  const [isNavOpened, setopen] = useAtom(navOpened);

  return (
    <div className="absolute top-11 -right-3">
      <button
        type="button"
        className="flex items-center justify-center w-6 h-6 bg-white rounded-full ring-2 ring-gray-200"
        onClick={() => setopen((previous) => !previous)}
      >
        {isNavOpened ? (
          <FontAwesomeIcon icon={faChevronLeft} width={8} />
        ) : (
          <FontAwesomeIcon icon={faChevronRight} width={8} />
        )}
      </button>
    </div>
  );
}
